import { KeyboardEventHandler } from 'react';

import {
  ENTER_KEY,
  CURRENTLY_FOCUSED_COUNTRY,
  OPEN_MENU_CLASS,
  CLOSE_MENU_CLASS,
  MARKETING_WEBSITE_DOMAINS,
} from 'constants/countriesDetails';

export const handleCountryOnEnterKey: KeyboardEventHandler = (e) => {
  const focusedCountry = document.querySelector(CURRENTLY_FOCUSED_COUNTRY);
  if (e.keyCode === ENTER_KEY) {
    focusedCountry.querySelector('a').click();
  }
};

export const handleMenuOnClose = (): void => {
  const countryMenu = document.querySelector(`.${OPEN_MENU_CLASS}`);
  const selectContainer = countryMenu?.parentElement;

  const menuClone = countryMenu?.cloneNode(true) as HTMLElement;

  if (!menuClone) {
    return;
  }

  menuClone.classList.add(CLOSE_MENU_CLASS);
  menuClone.addEventListener('animationend', () => {
    selectContainer?.removeChild(menuClone);
  });

  selectContainer?.appendChild(menuClone);
};

// To be extended in the future with localization
export const parseFooterDataUrls = ({
  url,
  locale,
}: {
  url: string;
  locale: string;
}): string => {
  if (url && url.startsWith('/')) {
    return `${
      (MARKETING_WEBSITE_DOMAINS[locale]
        ? MARKETING_WEBSITE_DOMAINS[locale]
        : MARKETING_WEBSITE_DOMAINS['en-GB']) as string
    }${url}`;
  }
  return url;
};
