import { EntryCollection } from 'contentful';
import { NextRouter } from 'next/router';

import { returnValueOrNull } from '../common';

import {
  ContentfulItem,
  ContentfulArticle,
  ContentfulSection,
  SectionTree,
  SectionTypeTree,
} from 'types/common';

export const filterArticlesBySection = ({
  articles,
  sectionId,
}: {
  articles: ContentfulArticle[];
  sectionId: string;
}): ContentfulArticle[] =>
  articles.filter(
    (item) =>
      item?.fields?.sections &&
      item.fields.sections.find(
        (section: ContentfulItem) => section?.sys?.id === sectionId,
      ),
  );

export const mapIdsOfContentfulItems = (
  contentfulItemsArray: Array<ContentfulItem>,
): string[] => contentfulItemsArray.map((item) => item?.sys?.id);

type MakeSectionTree = ({
  sections,
  contentfulRes,
  localizedSections,
}: {
  sections: Array<ContentfulSection>;
  contentfulRes: EntryCollection<ContentfulArticle['fields']>;
  localizedSections: string[];
}) => SectionTree;

export const makeSectionTree: MakeSectionTree = ({
  sections = [],
  contentfulRes,
  localizedSections,
}) =>
  sections
    .filter((section) => localizedSections.includes(section?.sys?.id))
    .reduce((sectionsAcc, currentSection): SectionTree => {
      const articlesInSection = filterArticlesBySection({
        articles: contentfulRes?.items,
        sectionId: currentSection?.sys?.id,
      });

      return {
        ...sectionsAcc,
        [currentSection.sys.id]: {
          ...currentSection.fields,
          icon: currentSection?.fields?.icon
            ? {
                url: returnValueOrNull(
                  currentSection?.fields?.icon?.fields?.file?.url,
                ),
                name: returnValueOrNull(
                  currentSection?.fields?.icon?.fields?.title,
                ),
              }
            : null,
          articles: mapIdsOfContentfulItems(articlesInSection),
          description: returnValueOrNull(
            currentSection?.fields?.metaDescription,
          ),
          sectionImage: currentSection?.fields?.sectionImage
            ? {
                url: returnValueOrNull(
                  currentSection?.fields?.sectionImage?.fields?.file?.url,
                ),
                name: returnValueOrNull(
                  currentSection?.fields?.sectionImage?.fields?.title,
                ),
              }
            : null,
        },
      };
    }, {});

type GroupSectionsByType = ({
  sectionTree,
  localizedSections,
}: {
  sectionTree: SectionTree;
  localizedSections: string[];
}) => SectionTypeTree;

export const groupSectionsByType: GroupSectionsByType = ({
  sectionTree,
  localizedSections,
}) =>
  // Get all unique section types
  Array.from(
    new Set(
      Object.keys(sectionTree)
        .filter((sectionId) => localizedSections.includes(sectionId))
        .reduce(
          (typesAcc: string[], currentSection) => [
            ...typesAcc,
            ...(sectionTree[currentSection]?.type || []),
          ],
          [],
        ),
    ),
  )
    // Map sections to right types
    .reduce(
      (typesAcc, currentType) => ({
        ...typesAcc,
        [currentType]: Object.keys(sectionTree).filter(
          (sectionId) =>
            sectionTree[sectionId]?.type &&
            sectionTree[sectionId].type.includes(currentType),
        ),
      }),
      {},
    );

export const getCurrentPageName: (router: NextRouter) => string = (router) =>
  router.pathname.split('/')[1];
