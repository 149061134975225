import { Entry } from 'contentful';
import React, { FC, forwardRef, Fragment, useMemo } from 'react';
import Link from 'next/link';
import * as icons from '@sumup/icons';
import { get } from 'lodash/fp';
import { useRouter } from 'next/router';

import { IndividualCountry, OptionText } from '../styled';

import { ContentfulCountry } from 'types/common';
import {
  isCountryInEnabledLocales,
  makeLocaleFromCountryAndLanguage,
} from 'services/localization';

type CountryOptionProps = {
  isDefault?: boolean;
  countryData: Entry<ContentfulCountry>;
  enabledLocales: string[];
};

const CountryOption: FC<CountryOptionProps> = ({
  isDefault = false,
  countryData,
  enabledLocales,
}) => {
  const router = useRouter();
  const Flag = useMemo(
    () =>
      get('fields.flagName', countryData)
        ? (icons[get('fields.flagName', countryData) as string] as FC)
        : Fragment,
    [countryData],
  );

  const ForwardedIndividualCountry = useMemo(
    () =>
      // eslint-disable-next-line prefer-arrow-callback
      forwardRef(function CustomIndividualCountry(
        { href = '' }: { href?: string },
        /* eslint-disable @typescript-eslint/no-unused-vars */
        _ref,
      ) {
        return (
          <IndividualCountry
            {...(href && { href })}
            key={get('fields.name', countryData) as string}
            onClick={() => {}}
          >
            <Flag />
            <OptionText>{get('fields.name', countryData)}</OptionText>
          </IndividualCountry>
        );
      }),
    [Flag, countryData],
  );

  return isDefault ? (
    <ForwardedIndividualCountry />
  ) : (
    <Link
      href={
        isCountryInEnabledLocales(
          get('fields.twoLetterIsoCode', countryData) as string,
          enabledLocales,
        )
          ? router.asPath
          : (get('fields.defaultZendeskSupportCentre', countryData) as string)
      }
      locale={
        isCountryInEnabledLocales(
          get('fields.twoLetterIsoCode', countryData) as string,
          enabledLocales,
        )
          ? makeLocaleFromCountryAndLanguage({
              country: get('fields.twoLetterIsoCode', countryData) as string,
              language: get(
                'fields.supportedLanguages[0].fields.twoLetterIsoCode',
                countryData,
              ) as string,
            })
          : false
      }
      passHref
    >
      <ForwardedIndividualCountry />
    </Link>
  );
};

export default CountryOption;
