import { elb } from '@elbwalker/walker.js';

import { getJsonFromSession } from './storage';

import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import { AnalyticsEvent } from 'src/types/common';
import { DataLayer } from 'src/types/global';

export const COMMON_PROPERTIES = {
  APP: 'support_centre',
  BUSINESS_FEATURE: 'support',
  BUSINESS_FLOW: 'search',
};

function getDataLayer(): DataLayer {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

const getPageType = (currentPage: string): string =>
  (ANALYTICS_CONSTANTS.PAGE_TYPES[currentPage] ||
    ANALYTICS_CONSTANTS.PAGE_TYPES.LANDING_PAGE) as string;

/**
 * @deprecated Use [Walker.js](https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22242394693/RFC+User+tracking+with+Walker.js) instead.
 */
const sendEvent = (event: AnalyticsEvent): void => {
  const user = getJsonFromSession('user') as {
    merchant_profile: { merchant_code: string };
  };
  // You can define multipler handlers and transform the base event to support different schemas.
  getDataLayer().push({
    ...event,
    Merchant_Code: user?.merchant_profile?.merchant_code,
  });
};

const sendButtonClick = ({
  buttonDescription,
  internalOrigin,
  locale,
  modalName,
  option,
  searchQuery,
  value,
  article_id,
}: {
  buttonDescription?: string;
  internalOrigin?: string;
  locale: string;
  modalName?: string;
  option?: string;
  searchQuery?: string;
  value?: string;
  article_id?: string;
}) =>
  elb('support event', {
    event: 'button_clicked',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: COMMON_PROPERTIES.BUSINESS_FLOW,
    button_description: buttonDescription,
    locale,
    ...(internalOrigin ? { internal_origin: internalOrigin } : {}),
    ...(modalName ? { modal_name: modalName } : {}),
    ...(option ? { option } : {}),
    ...(searchQuery ? { search_query: searchQuery } : {}),
    ...(value ? { value } : {}),
    ...(article_id ? { article_id } : {}),
  });

const sendEscalationInitiated = ({
  option,
  locale,
  article_id,
}: {
  option: string;
  locale: string;
  article_id: string;
}) =>
  elb('support event', {
    event: 'escalation_initiated',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: 'articles',
    article_id,
    locale,
    option,
  });

const sendLogin = ({ locale }: { locale: string }) =>
  elb('support event', {
    event: 'login',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    locale,
  });

const sendModalRendered = ({ locale }: { locale: string }) =>
  elb('support event', {
    event: 'modal-screen_rendered',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: COMMON_PROPERTIES.BUSINESS_FLOW,
    locale,
    modal_name: 'ai_search',
  });

const sendPageView = ({
  pageType,
  article_id,
  locale,
}: {
  pageType: string;
  article_id: string;
  locale: string;
}) =>
  elb('support event', {
    event: 'page_view',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: 'articles',
    content_type: pageType,
    ...(article_id ? { article_id } : {}),
    locale,
  });

const sendSearchEvent = ({
  query,
  event,
  action,
  rank = false,
  pageName,
  article_id,
  locale,
}: {
  query: string;
  event: string;
  action: string;
  rank?: boolean | number | string;
  pageName: string;
  article_id?: string;
  locale?: string;
}): void => {
  const targetProperties = rank ? `${query}:${rank.toString()}` : query;
  sendEvent({
    event,
    action,
    article_id,
    locale,
    'target': ANALYTICS_CONSTANTS.TARGETS.SUPPORT_CENTRE,
    'target-properties': targetProperties,
    'pageType': getPageType(pageName),
  });
};

const sendSearchResultsShown = ({
  locale,
  searchQuery,
  value,
  waitTime,
}: {
  locale: string;
  searchQuery: string;
  value: string;
  waitTime: number;
}) =>
  elb('support event', {
    event: 'result_shown',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: COMMON_PROPERTIES.BUSINESS_FLOW,
    locale,
    option: waitTime,
    search_query: searchQuery,
    value,
  });

const sendNewSearchEvent = ({
  query,
  locale,
  value,
}: {
  query: string;
  locale: string;
  value?: string;
}) => {
  sendEvent({
    'event': 'interaction',
    'action': 'intended_search_submitted',
    'target': 'customer_support_centre',
    'target-properties': query,
    locale,
    'app_screen_name': '',
    'widget_name': '',
    'business_feature': 'search',
    'report_type': value,
  });
  elb('support event', {
    event: 'button_clicked',
    business_feature: COMMON_PROPERTIES.BUSINESS_FEATURE,
    business_flow: 'ai_assistant',
    buttonDescription: 'intended_search_submission',
    searchQuery: query,
    option: value,
    locale,
  });
};

const createEventDispatcherForPackage =
  ({
    business_flow,
    internal_origin,
  }: {
    business_flow: string;
    internal_origin: string;
  }) =>
  () =>
    elb('support event', {
      business_flow,
      internal_origin,
    });

export default {
  getPageType,
  sendButtonClick,
  sendEscalationInitiated,
  sendEvent,
  sendLogin,
  sendModalRendered,
  sendPageView,
  sendSearchEvent,
  sendSearchResultsShown,
  sendNewSearchEvent,
  createEventDispatcherForPackage,
};
