import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { components } from 'react-select';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { Entry } from 'contentful';

import CountryOption from '../CountryOption';
import {
  SelectorContainer,
  CountryPickerStyles,
  CustomSelector,
} from '../styled';
import {
  handleCountryOnEnterKey,
  handleMenuOnClose,
} from '../../FooterServices';

import { ContentfulCountry } from 'types/common';
import { getCountryFromLocale } from 'services/localization';
import {
  CUSTOM_SELECTOR_PROPS,
  OPEN_MENU_CLASS,
} from 'constants/countriesDetails';

type CountryPickerProps = {
  countriesData: Array<Entry<ContentfulCountry>>;
};

const CountryPicker: FC<CountryPickerProps> = ({ countriesData }) => {
  const [enabledLocales, setEnabledLocales] = useState([]);
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    let isCancelled = false;
    const fetchLocales = async () => {
      if (!enabledLocales.length) {
        const response: { data: { enabledLocales: string } } = await axios.get(
          '/api/localization/enabled-locales',
        );

        if (!isCancelled) {
          setEnabledLocales(response.data.enabledLocales.split(','));
        }
      }
    };
    fetchLocales().catch(console.log);
    return () => {
      isCancelled = true;
    };
  }, [enabledLocales.length]);

  const selectMenu = (props) => (
    <components.Menu {...props} className={OPEN_MENU_CLASS} />
  );

  const countryOptions = useMemo(
    () =>
      [...countriesData]
        .sort((a, b) => (a.fields.name > b.fields.name ? 1 : -1))
        .reduce(
          (
            acc: { label: ReactNode; value: string }[],
            currentCountry: Entry<ContentfulCountry>,
          ) => [
            ...acc,
            currentCountry && {
              label: (
                <CountryOption
                  isDefault={
                    currentCountry.fields.twoLetterIsoCode ===
                    getCountryFromLocale(router.locale)
                  }
                  countryData={currentCountry}
                  enabledLocales={enabledLocales}
                />
              ),
              value: currentCountry.fields.twoLetterIsoCode,
            },
          ],
          [],
        ) as { label: ReactNode; value: string }[],
    [countriesData, router.locale, enabledLocales],
  );

  const pickerValue = useMemo(
    () =>
      countryOptions.find(
        (option) => option.value === getCountryFromLocale(router.locale),
      ),
    [countryOptions, router.locale],
  );

  const currentCountryData = useMemo(
    () =>
      countriesData.find(
        (country) =>
          country.fields.twoLetterIsoCode ===
          getCountryFromLocale(router.locale),
      ),
    [countriesData, router.locale],
  );

  return (
    <SelectorContainer>
      <CustomSelector
        {...CUSTOM_SELECTOR_PROPS}
        aria-label={t('footer.country_picker_aria_label')}
        options={countryOptions}
        placeholder={
          <CountryOption
            countryData={currentCountryData}
            enabledLocales={enabledLocales}
            isDefault
          />
        }
        styles={CountryPickerStyles()}
        onMenuClose={handleMenuOnClose}
        onKeyDown={handleCountryOnEnterKey}
        value={pickerValue}
        components={{
          Menu: selectMenu,
        }}
      />
    </SelectorContainer>
  );
};

export default CountryPicker;
