import React, { FC, useContext } from 'react';
import { useRouter } from 'next/router';
import { Body, Image, Headline } from '@sumup/circuit-ui';
import Link from 'next/link';
import { Entry } from 'contentful';

import styles from './footer.module.css';
import useContentfulFooter from './hooks/useContentfulFooter';
import { generateLinks, mapIconsToLabel, groupByThree } from './utils/helper';

import { AppContext } from 'src/context/AppContext';
import { ContentfulCountry } from 'src/types/common';

type FooterProps = {
  countriesData: Entry<ContentfulCountry>[];
};

const Footer: FC<FooterProps> = () => {
  const router = useRouter();
  const isLoaded = useContentfulFooter();
  const { state } = useContext(AppContext);

  const primaryLinks = state?.footer?.fields.primaryLinks;
  const secondaryLinks = state?.footer?.fields.secondaryLinks;
  const socialLinks = state?.footer?.fields.socialLinks;
  const disclaimer = state?.footer?.fields.disclaimer;
  const homeButton = state?.footer?.fields.homeButton;

  return isLoaded && state.footer ? (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.homeButton}>
          <Link href="/" passHref>
            <Image
              src={`https:${homeButton?.fields?.icon?.fields?.file?.url}`}
              alt="Sumup"
              className={styles.logo}
            />
          </Link>
          <Headline as="h2" size="three" className={styles.logoName}>
            <Link href="/">{homeButton?.fields.label}</Link>
          </Headline>
        </div>
      </div>
      <ul className={styles.menuContainer}>
        {primaryLinks &&
          groupByThree(primaryLinks).map((group, groupIndex) => (
            <ul key={groupIndex}>
              {group.map((primaryLink, index) => (
                <li key={index} className={styles.column}>
                  <Body
                    role="button"
                    onClick={() => router.push(generateLinks(primaryLink.url))}
                  >
                    {primaryLink.label}
                  </Body>
                </li>
              ))}
            </ul>
          ))}
      </ul>
      <div className={styles.legalNotesWrapper}>
        <div className={styles.legalNotes}>
          {secondaryLinks?.map((secondaryLink, index) => (
            <Body
              role="button"
              key={index}
              className={styles.legalNote}
              onClick={() => router.push(generateLinks(secondaryLink.url))}
            >
              {secondaryLink.label}
            </Body>
          ))}
        </div>
      </div>
      <div className={styles.socialLinks}>
        {socialLinks?.map((socialLink, index) => (
          <span key={index} className={styles.socialLink}>
            <Body
              role="button"
              onClick={() => router.push(generateLinks(socialLink.url))}
            >
              {mapIconsToLabel(socialLink.label)}
            </Body>
          </span>
        ))}
      </div>
      <div className={styles.disclaimerContainer}>
        <Body size="two" as="h2">
          {disclaimer}
        </Body>
      </div>
    </div>
  ) : null;
};

export default Footer;
