import { useEffect, useContext, useState } from 'react';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';

import { ContentfulFooter } from 'src/types/common';
import { CONTENT_TYPES } from 'src/constants/contentful';
import { contentfulRevampClient } from 'src/services/contentful';
import logger from 'src/services/logger';
import { AppContext } from 'src/context/AppContext';

function useContentfulFooter() {
  const { dispatch } = useContext(AppContext);
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState<boolean>(null);

  useEffect(() => {
    const fetchData = async (locale: string) => {
      try {
        const footerRes = await contentfulRevampClient.getEntries({
          content_type: CONTENT_TYPES.FOOTER,
          include: 10,
          locale,
        });
        const footer = footerRes.items[0] as Entry<ContentfulFooter>;
        dispatch({
          type: 'SET_FOOTER',
          payload: footer,
        });
        setIsLoaded(true);
      } catch (err) {
        logger.error(
          err as Error,
          'error fetching header data from contentful',
        );
      }
    };

    void fetchData(router.locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return isLoaded;
}

export default useContentfulFooter;
