import React, { FC, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Entry } from 'contentful';

import useContentfulHeader from '../Landing/hooks/useContentfulHeader';

import HeaderBranding from './HeaderBranding';
import styles from './topnav.module.css';
import HeaderNavigation from './HeaderNavigation';
import Search from './Search';

import { ContentfulCountry } from 'types/common';
import { AppContext } from 'src/context/AppContext';

type ComponentProps = {
  countriesData: Entry<ContentfulCountry>[];
};

const TopNav: FC<ComponentProps> = ({ countriesData }) => {
  const router = useRouter();
  const {
    state: { searchOverlay, header },
    dispatch,
  } = useContext(AppContext);
  useContentfulHeader();

  useEffect(() => {
    const handleRouteChange = () => {
      if (searchOverlay) {
        dispatch({ type: 'SET_SEARCH_OVERLAY', payload: false });
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [searchOverlay, router.events, dispatch]);

  return (
    <div key={router.locale} className={styles.topnavContainer}>
      <HeaderBranding countriesData={countriesData} />
      <Search />
      {!!header && <HeaderNavigation headerData={header} />}
    </div>
  );
};

export default TopNav;
