/* eslint-disable no-console */

import { withScope, captureException, captureMessage } from '@sentry/nextjs';

enum LogLevel {
  Debug,
  Info,
  Warn,
  Error,
}

const DEFAULT_LOG_LEVEL = LogLevel.Error;

export class Logger {
  private level: LogLevel;

  constructor(level: string | undefined) {
    this.level = Logger.parseLevel(level);
  }

  private static parseLevel(level?: string | undefined): LogLevel {
    if (typeof level === 'undefined') {
      return DEFAULT_LOG_LEVEL;
    }

    switch (level.toLowerCase()) {
      case 'debug':
        return LogLevel.Debug;
      case 'info':
        return LogLevel.Info;
      case 'warn':
        return LogLevel.Warn;
      case 'error':
        return LogLevel.Error;
      default:
        return DEFAULT_LOG_LEVEL;
    }
  }

  debug(...args: unknown[]): void {
    if (this.level > LogLevel.Debug) {
      return;
    }

    console.debug(...args);
  }

  info(message: string): void {
    if (this.level > LogLevel.Info) {
      return;
    }

    console.info(message);

    if (process.env.NODE_ENV === 'production') {
      captureMessage(message, 'info');
    }
  }

  warn(message: string): void {
    if (this.level > LogLevel.Warn) {
      return;
    }

    console.warn(message);
    if (process.env.NODE_ENV === 'production') {
      captureMessage(message, 'warning');
    }
  }

  /**
   * @deprecated Use the warn() method instead.
   */
  warning(message: string): void {
    this.warn(message);
  }

  error(err: Error, message: string): void {
    withScope((scope) => {
      if (message) {
        scope.setExtra('err_message', message);
      }

      if (process.env.NODE_ENV === 'production') {
        captureException(err);
      }
    });
  }
}

const logger = new Logger(process.env.NEXT_PUBLIC_LOG_LEVEL);

export default logger;
