import React, { createContext, Dispatch, useContext, useState } from 'react';

import { Children } from 'types/common';

const ResultsContext =
  createContext<{
    searchResults?: boolean;
    setSearchResults?: Dispatch<boolean>;
  }>(null);

const SearchResultsProvider = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  const [searchResults, setSearchResults] = useState<boolean>();

  return (
    <ResultsContext.Provider value={{ searchResults, setSearchResults }}>
      {children}
    </ResultsContext.Provider>
  );
};

const useSearchResults = (): {
  searchResults?: boolean;
  setSearchResults?: Dispatch<boolean>;
} =>
  useContext<{
    searchResults?: boolean;
    setSearchResults?: Dispatch<boolean>;
  }>(ResultsContext);

export { SearchResultsProvider, useSearchResults };
