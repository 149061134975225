const SumupLogo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9755 0H2.31023C1.03443 0 0 1.02042 0 2.28162V29.7185C0 30.9795 1.03443 32 2.31023 32H29.9755C31.2512 32 32.2858 30.9795 32.2858 29.7185V2.28162C32.2743 1.02042 31.2512 0 29.9755 0ZM20.2748 24.0889C17.4589 26.8865 12.9534 27.0125 9.98798 24.4557C9.9765 24.4443 9.9535 24.4328 9.94203 24.4099C9.75813 24.2264 9.75813 23.9398 9.93052 23.7563L19.9415 13.8273C20.1254 13.6553 20.4127 13.6553 20.5966 13.8388C23.2172 16.7854 23.1022 21.2798 20.2748 24.0889ZM22.3552 8.23217L12.3442 18.1612C12.1603 18.3332 11.8729 18.3332 11.689 18.1498C9.0685 15.2146 9.17195 10.7202 12.0109 7.91115C14.8268 5.11357 19.3323 4.99893 22.2977 7.54425C22.3092 7.55573 22.3322 7.56717 22.3437 7.5901C22.5276 7.7621 22.5276 8.04873 22.3552 8.23217Z"
      fill="black"
    />
  </svg>
);

export default SumupLogo;
