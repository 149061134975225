/* eslint-disable */
import React, { FC, forwardRef, ForwardRefRenderFunction } from 'react';
import Link from 'next/link';

import { SectionIcon, SectionLink, SectionTitle } from '../styled';

import { ResultSectionTypes } from 'types/search';

interface ComponentProps {
  sectionData: ResultSectionTypes;
  noLinks: boolean;
}

const ResultSection: FC<ComponentProps> = ({ sectionData, noLinks }) => {
  const SectionComponent: ForwardRefRenderFunction<
    HTMLButtonElement & HTMLAnchorElement
  > = (props, ref) => (
    <SectionLink {...props} noLinks={noLinks} ref={ref}>
      {sectionData.section_icon ? (
        <SectionIcon
          width={24}
          height={24}
          src={`https:${sectionData.section_icon}`}
          alt={sectionData.section_title}
        />
      ) : null}
      <SectionTitle size="one">{sectionData.section_title}</SectionTitle>
    </SectionLink>
  );
  const WrappedSectionComponent = forwardRef(SectionComponent);

  return noLinks ? (
    <WrappedSectionComponent />
  ) : (
    <Link passHref href={`/sections/${sectionData.section_id}`}>
      <WrappedSectionComponent />
    </Link>
  );
};

export default ResultSection;
