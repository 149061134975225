import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import axios from 'axios';
import { AuthState, useAuth } from '@sumup/react-nanoauth';
import { useRouter } from 'next/router';

import { STORAGE_KEYS } from 'constants/user';
import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import { User, UserContextProps } from 'types/context';
import * as Analytics from 'services/analytics';
import { setJsonToSession } from 'services/storage';
import logger from 'services/logger';
import { Children } from 'types/common';
import globalContext from 'src/components/GlobalContext';

const UserContext = createContext({} as UserContextProps);

type UserProviderType = {
  children: Children;
};

const sendLoginEvent = ({ locale, user }: { locale: string; user: User }) => {
  Analytics.AnalyticsEvents.sendEvent({
    event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
    action: ANALYTICS_CONSTANTS.ACTIONS.LOGIN,
    target: ANALYTICS_CONSTANTS.TARGETS.SUPPORT_CENTRE,
    locale,
    Merchant_Code: user?.merchant_profile?.merchant_code,
  });
  Analytics.sendOptimizelyEvent({
    event: 'support_centre_login',
    locale,
    tags: {
      Merchant_Code: user?.merchant_profile?.merchant_code,
      locale,
    },
    user,
  });
  Analytics.AnalyticsEvents.sendLogin({
    locale,
  });
};

const UserProvider = ({ children }: UserProviderType): JSX.Element => {
  const [user, setUser] = useState<User>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const auth = useAuth();
  const { authenticated } = useContext(globalContext);
  const router = useRouter();

  useEffect(() => {
    if (
      auth.authState === AuthState.Initializing ||
      (router.query.code && auth.authState !== AuthState.Authenticated)
    ) {
      auth
        .handleCallback()
        .catch((authErr: Error) =>
          logger.error(authErr, 'Auth handleCallback failed'),
        );
    }
  }, [auth.authState, router.query.code]);

  const fetchMerchantProfile = useCallback(
    async (tokenRes: string) => {
      try {
        const userRes = await axios.get('/api/merchant-profile', {
          headers: {
            'x-jwt': tokenRes,
          },
        });
        if (loggedIn) {
          sendLoginEvent({
            locale: router.locale,
            user: userRes.data as User,
          });
          setJsonToSession(
            STORAGE_KEYS.USER,
            userRes.data as { [key: string]: string },
          );
          setUser(userRes.data as User);
        }
      } catch (err) {
        logger.error(err as Error, 'Could not retrieve merchant profile');
      }
    },
    [loggedIn, router.locale],
  );

  const fetchUser = useCallback(async () => {
    try {
      const tokenRes: string | undefined = await auth.getAccessToken();
      if (tokenRes) {
        await fetchMerchantProfile(tokenRes);
      }
    } catch (err) {
      logger.error(err as Error, 'Could not retrieve access token');
    }
  }, [auth, fetchMerchantProfile]);

  useEffect(() => {
    if (authenticated && !user) {
      void fetchUser();
    }
  }, [authenticated, user, fetchUser]);

  useEffect(() => {
    if (authenticated && !loggedIn) {
      setLoggedIn(true);
      setJsonToSession(STORAGE_KEYS.HAS_LOGGED_IN, true);
    }
  }, [authenticated, loggedIn]);

  const providerValue = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContextProps => useContext(UserContext);

export { UserProvider, useUser };
