import React, { useState, useEffect, useMemo, FC } from 'react';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { useRouter } from 'next/router';
import { Entry } from 'contentful';

import CountryOptions from '../CountryOptions';
import LanguageOptions from '../LanguageOptions';

import { localeInfo } from 'services/get-locale-info';
import {
  CountryPickerTabs,
  TabButton as StyledTabButton,
  TabsContainer,
} from 'components/Header/components/styled';
import { MobileComponent } from 'components/styledComponents';
import { ContentfulCountry } from 'types/common';

type CountryPickerProps = {
  countriesData: Entry<ContentfulCountry>[];
  removeModal: () => void;
};

const TabButton = StyledTabButton as unknown as FC<{
  children: string;
  selected: boolean;
  onClick: () => void;
}>;

const MobileCountryPickerModal: FC<CountryPickerProps> = ({
  countriesData = [],
  removeModal,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const currentCountry = localeInfo(router.locale).country;
  const [selected, setSelected] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [enabledLocales, setEnabledLocales] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetchLocales = async () => {
      if (!enabledLocales.length) {
        const response: { data: { enabledLocales: string } } = await axios.get(
          '/api/localization/enabled-locales',
        );

        if (!isCancelled) {
          setEnabledLocales(response.data.enabledLocales.split(','));
        }
      }
    };
    fetchLocales().catch(console.log);
    return () => {
      isCancelled = true;
    };
  }, [enabledLocales.length]);

  const selectedCountryData = useMemo(
    () =>
      countriesData.find(
        (country) => country.fields.twoLetterIsoCode === selectedCountry,
      ),
    [countriesData, selectedCountry],
  );

  const countriesList = useMemo(
    () =>
      countriesData
        ? countriesData
            .map((el) => el.fields)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        : [],
    [countriesData],
  );

  const handleCountryClick = (el: ContentfulCountry): void => {
    const availableLanguages = el.supportedLanguages.map((item) => item.fields);
    setSelected(true);
    if (el.supportedLanguages.length > 1) {
      setSelectedCountry(el.twoLetterIsoCode);
      setLanguages(availableLanguages.map((language) => language));
    } else {
      removeModal();
    }
  };

  return (
    <MobileComponent>
      <TabsContainer>
        <CountryPickerTabs>
          <TabButton
            key={'Country'}
            selected={!selected}
            onClick={() => {
              setSelected(false);
              setLanguages([]);
            }}
          >
            {t('header.select_country')}
          </TabButton>
          {languages.length > 0 && (
            <TabButton
              key={'Language'}
              selected={selected}
              onClick={() => setSelected(true)}
            >
              {t('header.select_language')}
            </TabButton>
          )}
        </CountryPickerTabs>
        {!selected ? (
          <CountryOptions
            countriesList={countriesList}
            currentCountry={currentCountry}
            handleCountryClick={handleCountryClick}
            enabledLocales={enabledLocales}
            removeModal={removeModal}
          />
        ) : (
          <LanguageOptions
            enabledLocales={enabledLocales}
            removeModal={removeModal}
            router={router}
            selectedCountryData={selectedCountryData}
          />
        )}
      </TabsContainer>
    </MobileComponent>
  );
};
export default MobileCountryPickerModal;
