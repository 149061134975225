import React, {
  useState,
  FC,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import Link from 'next/link';
import { Headline, Image, useModal } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { get } from 'lodash/fp';

import { CountryFlagContainer, CountryFlag } from '../styled';
import DesktopCountryPicker from '../CountryPicker/components/DesktopCountryPicker';
import MobileCountryPickerModal from '../CountryPicker/components/MobileCountryPickerModal';
import useScreenWidth from '../../hooks/useScreenWidth';

import styles from './headerBranding.module.css';

import {
  DesktopComponent,
  MobileCountryPicker,
} from 'components/styledComponents';
import { getCountryFromLocale } from 'services/localization';
import { ContentfulCountry } from 'types/common';
import { AppContext } from 'src/context/AppContext';

const HeaderBranding: FC<{
  countriesData: Entry<ContentfulCountry>[];
}> = ({ countriesData }) => {
  const { state } = useContext(AppContext);
  const { setModal, removeModal } = useModal();
  const [isCountryPickerOpened, setIsCountryPickerOpened] = useState(false);
  const router = useRouter();
  const screenWidth = useScreenWidth();

  const [label, setLabel] = useState<string>(undefined);
  const [url, setUrl] = useState<string>(undefined);

  // This is needed because the state header can be null (typescript strict check is disabled for this project)
  useEffect(() => {
    if (state?.header?.fields?.homeButton?.fields?.label) {
      setLabel(state.header.fields.homeButton.fields.label);
    }
    if (state?.header?.fields?.homeButton?.fields?.icon?.fields?.file?.url) {
      setUrl(state.header.fields.homeButton.fields.icon.fields.file.url);
    }
  }, [state]);

  const currentCountryData = useMemo(
    () =>
      countriesData?.find(
        (item) =>
          item.fields.twoLetterIsoCode === getCountryFromLocale(router.locale),
      ),
    [countriesData, router.locale],
  );

  const MemoizedMobileModal = useMemo(
    () => (
      <MobileCountryPickerModal
        removeModal={removeModal}
        countriesData={countriesData}
      />
    ),
    [countriesData, removeModal],
  );

  const handleMobileFlagClick = useCallback(
    () =>
      setModal({
        css: css`
          padding: 0 !important;
        `,
        closeButtonLabel: 'Close',
        children: MemoizedMobileModal,
      }),
    [MemoizedMobileModal, setModal],
  );

  const handleDesktopFlagClick = () =>
    setIsCountryPickerOpened(!isCountryPickerOpened);

  return (
    <div className={styles.container}>
      <div className={styles.link}>
        {url && (
          <Link href="/" passHref>
            <Image src={`https:${url}`} alt="sumup" className={styles.logo} />
          </Link>
        )}
        <Headline
          as="h2"
          size={screenWidth < 768 ? 'four' : 'three'}
          className={styles['logo-name']}
        >
          {label && <Link href="/">{label}</Link>}
        </Headline>
      </div>

      {countriesData && (
        <>
          <DesktopComponent>
            <CountryFlagContainer
              icon={({ className }) => (
                <CountryFlag
                  flagName={
                    get('fields.flagName', currentCountryData) as string
                  }
                  className={className}
                />
              )}
              onClick={handleDesktopFlagClick}
            >
              Country Picker
            </CountryFlagContainer>
          </DesktopComponent>
          <MobileCountryPicker>
            <CountryFlagContainer
              icon={({ className }) => (
                <CountryFlag
                  flagName={
                    get('fields.flagName', currentCountryData) as string
                  }
                  className={className}
                />
              )}
              onClick={handleMobileFlagClick}
            >
              Country Picker
            </CountryFlagContainer>
          </MobileCountryPicker>
          <DesktopCountryPicker
            countriesData={countriesData}
            isCountryPickerOpened={isCountryPickerOpened}
            setIsCountryPickerOpened={setIsCountryPickerOpened}
          />
        </>
      )}
    </div>
  );
};

export default HeaderBranding;
