import { useEffect, useContext, useState } from 'react';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';

import { ContentfulHeader } from 'src/types/common';
import { CONTENT_TYPES } from 'src/constants/contentful';
import { contentfulRevampClient } from 'src/services/contentful';
import logger from 'src/services/logger';
import { AppContext } from 'src/context/AppContext';

function useContentfulHeader() {
  const { dispatch } = useContext(AppContext);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headerRes = await contentfulRevampClient.getEntries({
          content_type: CONTENT_TYPES.HEADER,
          include: 10,
          locale: router.locale,
        });
        const header = headerRes.items[0] as Entry<ContentfulHeader>;
        dispatch({
          type: 'USER_CATEOGIRES',
          payload: header,
        });
        setIsLoaded(true);
      } catch (err) {
        setIsLoaded(false);
        logger.error(
          err as Error,
          'error fetching header data from contentful',
        );
      }
    };

    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return isLoaded;
}

export default useContentfulHeader;
