import React, { createContext, useContext, useState } from 'react';

import { Children } from 'types/common';
import { SearchScrollContextProps } from 'types/context';

const ScrollContext = createContext<SearchScrollContextProps>(null);

const ScrollProvider = ({ children }: { children: Children }): JSX.Element => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  return (
    <ScrollContext.Provider value={{ isScrolled, setIsScrolled }}>
      {children}
    </ScrollContext.Provider>
  );
};

const useScroll = (): SearchScrollContextProps =>
  useContext<SearchScrollContextProps>(ScrollContext);

export { ScrollProvider, useScroll };
