import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { CustomLiveAgentConfig } from 'src/types/context';
import { setJsonToLocal } from 'src/services/storage';
import { CUSTOM_LIVE_AGENT } from 'src/constants/salesforce';

const Container = styled('div')(
  ({ shouldOpen }: { shouldOpen: boolean }) => css`
    bottom: 0px;
    right: 0px;
    position: fixed;
    width: ${shouldOpen ? '300px' : '0px'};
    height: ${shouldOpen ? '500px' : '0px'};
    z-index: 1;
  `,
);

const LiveAgent: FC<{
  customConfig?: CustomLiveAgentConfig;
  shouldOpen: boolean;
}> = ({ customConfig, shouldOpen = false }) => {
  useEffect(() => {
    if (customConfig) {
      setJsonToLocal(CUSTOM_LIVE_AGENT, { openChat: false, ...customConfig });
    }
  }, [customConfig]);

  return (
    <Container id="liveAgentContainer" shouldOpen={shouldOpen}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        id="liveAgent"
        title="Live Agent"
        src="/liveAgent.html"
      />
    </Container>
  );
};

export default LiveAgent;
