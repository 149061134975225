import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-intersection-observer';

import Search from './components/Search';
import { Header, Greeting } from './components/styled';
import PosGreeting from './components/PosGreeting';

import { HeaderProps } from 'types/components';
import { useScroll } from 'providers/SearchScroll';
import { useSearchResults } from 'providers/SearchResults';
import { isPosLandingPage, isPosPage } from 'services/pos';

const Component: FC<HeaderProps> = ({ overlayEnabled, setOverlayEnabled }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const inViewOffset = '-50px 0px 0px 0px';
  const { ref, inView } = useInView({
    threshold: 1.0,
    rootMargin: inViewOffset,
  });
  const scrollContext = useScroll();
  const searchResultsContext = useSearchResults();

  useEffect(() => {
    if (window.scrollY) {
      scrollContext.setIsScrolled(!inView);
    }
  }, [inView, scrollContext]);

  const isArticleOrSectionPage = () =>
    router.pathname.includes('articles') ||
    router.pathname.includes('sections');

  const isSearchResultsPage = () => router.pathname.includes('/search');

  return (
    <Header
      isSearch={isSearchResultsPage()}
      searchResults={searchResultsContext.searchResults}
      isPos={isPosPage(router)}
      key={router.locale}
    >
      {isPosPage(router) ? (
        isPosLandingPage(router) && <PosGreeting />
      ) : (
        <>
          {router.pathname === '/' ? (
            <Greeting
              onClick={() => {
                setOverlayEnabled(false);
              }}
              variant="highlight"
            >
              {t('header.greeting')}
            </Greeting>
          ) : null}
          <Search
            centered={!isArticleOrSectionPage()}
            scrolledElement={ref}
            overlayEnabled={overlayEnabled}
            setOverlayEnabled={setOverlayEnabled}
            inView={inView}
          />
        </>
      )}
    </Header>
  );
};

export default Component;
