import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '@sumup/react-nanoauth';
import { useRouter } from 'next/router';

import { STORAGE_KEYS } from 'constants/user';
import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import { User } from 'types/context';
import * as Analytics from 'services/analytics';
import { getJsonFromSession, setJsonToSession } from 'services/storage';
import logger from 'services/logger';
import { AppContext } from 'src/context/AppContext';

const sendLoginEvent = ({ locale, user }: { locale: string; user: User }) => {
  const hasLoggedIn = getJsonFromSession(STORAGE_KEYS.HAS_LOGGED_IN);

  if (!hasLoggedIn) {
    Analytics.AnalyticsEvents.sendEvent({
      event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
      action: ANALYTICS_CONSTANTS.ACTIONS.LOGIN,
      target: ANALYTICS_CONSTANTS.TARGETS.SUPPORT_CENTRE,
      locale,
      Merchant_Code: user?.merchant_profile?.merchant_code,
    });
    Analytics.sendOptimizelyEvent({
      event: 'support_centre_login',
      locale,
      tags: {
        Merchant_Code: user?.merchant_profile?.merchant_code,
        locale,
      },
      user,
    });
    Analytics.AnalyticsEvents.sendLogin({
      locale,
    });
  }
  setJsonToSession(STORAGE_KEYS.HAS_LOGGED_IN, true);
};

const WithUser = ({ children }): JSX.Element => {
  const auth = useAuth();

  const { state, dispatch } = useContext(AppContext);

  const router = useRouter();

  useEffect(() => {
    if (state.authenticated && Object.keys(state.user).length === 0) {
      auth
        .getAccessToken()
        .then((tokenRes) => {
          if (tokenRes) {
            axios
              .get('/api/merchant-profile', {
                headers: {
                  'x-jwt': tokenRes,
                },
              })
              .then((userRes) => {
                sendLoginEvent({
                  locale: router.locale,
                  user: userRes.data as User,
                });
                dispatch({
                  type: 'SET_USER',
                  payload: userRes.data as User,
                });
                setJsonToSession(
                  STORAGE_KEYS.USER,
                  userRes.data as { [key: string]: string },
                );
              })
              // To add error logging
              .catch((err: Error) =>
                logger.error(err, 'Could not retrieve merchant profile'),
              );
          }
        })
        .catch((err: Error) =>
          logger.error(err, 'Could not retrieve access token'),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, state.authenticated]);

  return <>{children}</>;
};

export default WithUser;
