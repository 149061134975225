export const DEFAULT_LOCALES = {
  SMARTLING: 'en',
  CONTENTFUL: 'Intl',
  APP: 'en-GB',
  ZENDESK: 'hc',
};

export const ZENDESK_LOCALES_TO_APP: { [key: string]: string } = {
  'en-gb': 'en-GB',
  'en-ie': 'en-IE',
  'en-us': 'en-US',
  'bg': 'bg-BG',
  'cs': 'cs-CZ',
  'cl': 'es-CO',
  'da': 'da-DK',
  'da-dk': 'da-DK',
  'dk': 'da-DK',
  'de': 'de-DE',
  'de-at': 'de-AT',
  'de-ch': 'de-CH',
  'de-lu': 'de-LU',
  'el-cy': 'el-CY',
  'el': 'el-GR',
  'es': 'es-ES',
  'es-cl': 'es-CL',
  'es-co': 'es-CO',
  'es-pe': 'es-PE',
  'es-us': 'es-US',
  'et': 'et-EE',
  'fi': 'fi-FI',
  'fr-be': 'fr-BE',
  'fr-ch': 'fr-CH',
  'fr': 'fr-FR',
  'fr-lu': 'fr-LU',
  'hr': 'hr-HR',
  'hu': 'hu-HU',
  'it-ch': 'it-CH',
  'it': 'it-IT',
  'lt': 'lt-LT',
  'lv': 'lv-LV',
  'no': 'nb-NO',
  'nl-be': 'nl-BE',
  'nl': 'nl-NL',
  'pl': 'pl-PL',
  'pt-br': 'pt-BR',
  'pt': 'pt-PT',
  'ro': 'ro-RO',
  'sk': 'sk-SK',
  'sl': 'sl-SI',
  'sv': 'sv-SE',
  'au': 'en-AU',
};
