import { Body, Headline } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { PosGreetingCard, PosGreetingsContainer } from '../styled';

const GREETING_IMAGE_URL =
  'https://images.ctfassets.net/214q1nptnllb/LRIlckAzjNAaBDoIZpRo1/ae3e8127689c57b4a1140bdb0c743f05/HARDWARE.png';

const PosGreeting = () => {
  const { t } = useTranslation();

  return (
    <PosGreetingCard>
      <PosGreetingsContainer>
        <Headline as="h2" size="one" css={spacing({ bottom: 'kilo' })}>
          {t('header.greeting')}
        </Headline>
        <Body size="one">{t('header.pos.body')}</Body>
      </PosGreetingsContainer>
      <Image alt="POS Pro" width={305} height={250} src={GREETING_IMAGE_URL} />
    </PosGreetingCard>
  );
};

export default PosGreeting;
