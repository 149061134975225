/* eslint-disable */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Anchor, Card } from '@sumup/circuit-ui';

import { StyledComponentsParams } from 'types/components';
import { MAX_SEARCH_WIDTH_PX } from 'constants/search';

export const LinkToResultsPage = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    padding: ${theme.spacings.giga} 0;
    display: flex;
    justify-content: center;
    color: var(--cui-bg-accent-strong);
    text-decoration: none;
    transition: all 0.18s ease;

    &:hover,
    &:visited:hover {
      text-decoration: underline;
      color: var(--cui-bg-accent-strong);
      background-color: var(--cui-bg-subtle);
    }

    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-accent-strong);
    }
    &:focus {
      z-index: ${theme.zIndex.absolute};
    }
  `,
);

export const ResultLink = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    text-decoration: none;
    position: relative;

    &:focus {
      z-index: ${theme.zIndex.absolute};
    }
  `,
);

export const ResultWrapper = styled('div')(
  ({
    theme,
    linkToResults,
  }: StyledComponentsParams & { linkToResults?: boolean }) => css`
    width: 100%;
    ${!linkToResults &&
    `border-bottom: ${theme.borderWidth.kilo} solid var(--cui-bg-normal-pressed);`}
    padding: ${theme.spacings.bit};

    &:hover {
      background-color: var(--cui-bg-subtle);
      ${!linkToResults &&
      `border-bottom: calc(2 * ${theme.borderWidth.mega}) solid
        var(--cui-bg-accent-strong)`};
      transition: all 0.18s ease;
    }
  `,
);

export const SuggestionsContainer = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    margin: auto;
    padding: 0;
    border-radius: ${theme.borderRadius.mega};
    overflow: auto;
  `,
);
