import { Button, SearchInput } from '@sumup/circuit-ui';
import React, {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import styles from './search.module.css';
import Article from './components/Article';

import {
  EMPTY_SEARCH_RESULTS,
  INITIAL_PAGE,
  MIN_LENGTH_OF_SEARCH_TERM,
  RESULTS_PER_PAGE_SEARCH_BAR,
} from 'src/constants/search';
import { getSearchResults } from 'src/services/api/search';
import { SearchResults } from 'src/types/search';
import { useScroll } from 'providers/SearchScroll';
import { AppContext } from 'src/context/AppContext';

const Search: FC = () => {
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] =
    useState<SearchResults>(EMPTY_SEARCH_RESULTS);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const router = useRouter();
  const scrollContext = useScroll();
  const {
    state: { searchOverlay },
    dispatch,
  } = useContext(AppContext);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuery(e.target.value);
  };

  const wrapperRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  const enableOverlay = useCallback(() => {
    if (scrollContext.isScrolled) {
      dispatch({ type: 'SET_SEARCH_OVERLAY', payload: true });
    }
  }, [scrollContext.isScrolled, dispatch]);

  const disableOverlay = useCallback(() => {
    if (scrollContext.isScrolled) {
      dispatch({ type: 'SET_SEARCH_OVERLAY', payload: false });
    }
  }, [scrollContext.isScrolled, dispatch]);

  const handleNavSearchBlur = useCallback(() => {
    if (scrollContext.isScrolled) {
      wrapperRef?.current?.blur();
      setQuery('');
      setSearchResults(EMPTY_SEARCH_RESULTS);
      disableOverlay();
    }
  }, [disableOverlay, scrollContext.isScrolled]);

  useEffect(() => {
    if (!searchOverlay) {
      handleNavSearchBlur();
    }
  }, [handleNavSearchBlur, searchOverlay]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (query.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        getSearchResults({
          page: INITIAL_PAGE,
          size: RESULTS_PER_PAGE_SEARCH_BAR,
          locale: router.locale,
          searchTerm: query,
        })
          .then((searchResponse) => setSearchResults(searchResponse.data))
          .catch(() => {});
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [query, router.locale]);

  useEffect(() => {
    if (searchResults.articles.length > 0) {
      setShowSearchResults(true);
    }
  }, [searchResults]);

  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      if (
        wrapperRef?.current &&
        !wrapperRef?.current.contains(event.target as HTMLElement)
      ) {
        setShowSearchResults(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleContainerFocus = () => {
    enableOverlay();
  };

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      if (query.length) {
        router.push(`/search/${query}`).catch(() => {});
        setShowSearchResults(false);
      }
    }
  };

  const handleClear = () => {
    setQuery('');
    setShowSearchResults(false);
  };

  return (
    <div className={styles.container}>
      <SearchInput
        hideLabel
        label="Search"
        placeholder="Search for answers"
        className={styles.search}
        inputClassName={styles.inputSearch}
        value={query}
        onChange={handleInputChange}
        onFocus={handleContainerFocus}
        onKeyUp={handleKeyPress}
        onClear={handleClear}
        clearLabel="Reset"
      />
      {showSearchResults ? (
        <div className={styles.searchResultsDropdown} ref={wrapperRef}>
          {searchResults.articles.map((article) => (
            <div key={article.id} className={styles.searchRestulsContainer}>
              <Article
                article={article}
                onClick={() => {
                  setShowSearchResults(false);
                }}
                isMobile={false}
              />
            </div>
          ))}
          <div className={styles.seeAllResultsButtonWrapper}>
            <Button
              variant="tertiary"
              onClick={() => {
                router
                  .push(`/search/${query}`)
                  .then(() => {
                    setShowSearchResults(false);
                  })
                  .catch(console.error);
              }}
            >
              See all results
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;
