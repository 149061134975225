import React, { FC } from 'react';
import Head from 'next/head';

export interface MetaProps {
  title: string;
  description?: string | null;
  canonicalTag: string | boolean;
  path: string;
  siteName?: string;
  image?: {
    src: string;
    alt: string;
  };
  type?: string;
  updatedAt?: string;
  index?: boolean;
  follow?: boolean;
  locale?: string;
  twitter?: string;
}

/**
 * Add meta tags to the document head.
 */
export const Meta: FC<MetaProps> = ({ title, description, canonicalTag }) => (
  <Head>
    {/* SEO */}
    <title>{title}</title>
    {description ? <meta name="description" content={description} /> : null}
    {canonicalTag ? (
      <link rel="canonical" href={`${canonicalTag as string}`} />
    ) : null}
  </Head>
);
