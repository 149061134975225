import Select from 'react-select';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SumUpLogo } from '@sumup/icons';
import { Anchor, Body } from '@sumup/circuit-ui';
import { light as circuitTheme } from '@sumup/design-tokens';

import { MENU_HEIGHT, MENU_WIDTH } from 'constants/countriesDetails';
import { StyledComponentsParams } from 'types/components';

export const BottomLinksWrapper = styled.nav(
  ({ theme }: StyledComponentsParams) => css`
    margin-top: ${theme.spacings.giga};
  `,
);

export const BottomLinksList = styled.ul(
  ({ theme }: StyledComponentsParams) => css`
    list-style: none;
    ${theme.mq.kilo} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  `,
);

export const Disclaimer = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    margin-top: ${theme.spacings.tera};
    margin-bottom: ${theme.spacings.peta};
    text-align: left;
    ${theme.mq.mega} {
      text-align: center;
    }
  `,
);

export const FooterContainer = styled.footer(
  ({ theme }: StyledComponentsParams) => css`
    margin-top: calc(2 * ${theme.spacings.peta});
    width: 100%;
  `,
);

export const FooterLogo = styled(SumUpLogo)(
  ({ theme }: StyledComponentsParams) => css`
    height: calc(${theme.spacings.tera} + ${theme.spacings.bit});
    ${theme.mq.untilMega} {
      height: ${theme.spacings.tera};
    }
  `,
);

export const FooterWrapperTop = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    color: var(--cui-fg-subtle);
    background-color: var(--cui-bg-subtle);
    padding-top: ${theme.spacings.exa};
    padding-bottom: ${theme.spacings.tera};
  `,
);

export const FooterWrapperBottom = styled('div')(
  () => css`
    width: 100%;
    color: var(--cui-fg-subtle);
  `,
);

export const LogoLink = styled(Anchor)(
  () => css`
    width: fit-content;
    display: flex;
    color: var(--cui-bg-strong);
    &:visited,
    &:visited:active,
    &:visited:hover,
    &:hover,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }
  `,
);

export const LogoWrapper = styled.div(
  ({ theme }: StyledComponentsParams) => css`
    padding-bottom: ${theme.spacings.tera};
  `,
);

export const PrimaryLinksContainer = styled.nav(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${theme.mq.kiloToMega} {
      margin-top: ${theme.spacings.tera};
    }
    ${theme.mq.untilKilo} {
      margin-bottom: ${theme.spacings.giga};
    }
  `,
);

export const PrimaryLinksItem = styled.li(
  ({ theme }: StyledComponentsParams) => css`
    color: var(--cui-fg-subtle);
    margin-bottom: ${theme.spacings.byte};

    a {
      border-radius: 0;
    }
  `,
);

export const PrimaryLinksList = styled.ul(
  ({ theme, colNumber }: StyledComponentsParams & { colNumber: number }) => css`
    width: 100%;
    list-style: none;
    ${theme.mq.kilo} {
      width: ${(100 / colNumber).toFixed(2)}%;
    }
  `,
);

export const SecondaryLinksItem = styled.li(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.typography.body.two};
    margin-bottom: ${theme.spacings.byte};
    ${theme.mq.kilo} {
      display: inline;
      margin-right: ${theme.spacings.tera};
    }
    ${theme.mq.mega} {
      ${theme.typography.body.one};
    }

    a {
      padding: calc(${theme.spacings.bit} / 4) 0;
      border-radius: 0;
    }
  `,
);

export const SecondaryLinksList = styled.ul(
  () => css`
    list-style: none;
  `,
);

export const SocialLink = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    color: var(--cui-fg-subtle);
    display: inline-block;
    margin-top: ${theme.spacings.kilo};
    margin-bottom: ${theme.spacings.kilo};
    margin-left: 0;
    margin-right: ${theme.spacings.mega};
    &:last-child {
      margin-right: 0;
    }
  `,
);

export const StyledAnchor = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    color: var(--cui-fg-subtle);
    text-decoration: none;
    border-bottom: ${theme.borderWidth.kilo} solid;
    border-bottom-color: transparent;
    transition: color ${theme.transitions.default},
      border-bottom-color ${theme.transitions.default};
    &:hover,
    &:visited:hover,
    &:visited:active,
    &:visited:focus,
    &:focus,
    &:active {
      color: var(--cui-bg-accent-strong);
      border-bottom-color: var(--cui-bg-accent-strong);
    }
    &:visited {
      color: inherit;
    }
  `,
);

export const IndividualCountry = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: inherit;
    &:visited,
    &:visited:active,
    &:visited:hover,
    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
    padding: ${theme.spacings.kilo} ${theme.spacings.byte};

    svg {
      margin-right: ${theme.spacings.byte};
      width: ${theme.spacings.tera};
      height: ${theme.spacings.giga};
    }
  `,
);

export const SelectorContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;

    ${theme.mq.untilMega}, ${theme.mq.kiloToMega}: {
      flex-direction: row;
      margin-top: ${theme.spacings.mega};
    }
    ${theme.mq.mega}, ${theme.mq.mega}: {
      flex-direction: row-reverse;
    }
  `,
);

const optionsBackgroundColor = ({ isDisabled, isFocused, isSelected }) => {
  if (isDisabled) {
    return null;
  }
  if (isSelected) {
    return 'transparent';
  }
  if (isFocused) {
    return 'var(--cui-bg-subtle)';
  }
  return null;
};

const optionsColor = ({ isDisabled, isFocused }) => {
  if (isDisabled) {
    return null;
  }
  if (isFocused) {
    return 'var(--cui-fg-accent)';
  }
  return 'var(--cui-border-normal-pressed)';
};

export const CustomSelector = styled(Select)`
  @keyframes openMenu {
    0% {
      height: 0;
    }
    100% {
      height: ${MENU_HEIGHT}px;
    }
  }
  @keyframes closeMenu {
    0% {
      height: ${MENU_HEIGHT}px;
    }
    100% {
      height: 0;
    }
  }
  .openMenu {
    overflow: hidden;
    animation: openMenu 0.2s ease-in-out;
  }

  .closeMenu {
    animation: closeMenu 0.2s ease-in-out;
  }
`;

export const OptionText = styled(Body)(
  () => css`
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  `,
);

export const CountryPickerStyles = (): Record<string, unknown> => ({
  singleValue: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'var(--cui-border-normal-pressed)',
  }),
  valueContainer: (provided: Record<string, unknown>) => ({
    ...provided,
    padding: 0,
  }),
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    width: `${MENU_WIDTH}px`,
  }),
  dropdownIndicator: (
    provided: Record<string, unknown>,
    state: {
      selectProps: {
        menuIsOpen: boolean;
      };
    },
  ) => ({
    ...provided,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
  indicatorSeparator: (provided: Record<string, unknown>) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    backgroundColor: 'var(--cui-bg-normal)',
    cursor: 'pointer',
    height: `${circuitTheme.spacings.exa}`,
    border: '1px solid var(--cui-fg-placeholder)',
  }),
  menuList: (provided: Record<string, unknown>) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    align: 'middle',
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    marginBottom: 0,
    borderwidth: 10,
    boxShadow:
      'var(--cui-bg-highlight) 0px 0px 0px 1px, var(--cui-bg-highlight) 0px 0px 1px 0px, var(--cui-bg-highlight) 0px 2px 2px 0px',
  }),
  option: (
    styles: Record<string, unknown> & {
      ':active': Record<string, unknown>;
    },
    {
      isDisabled,
      isFocused,
      isSelected,
    }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean },
  ) => ({
    ...styles,
    'position': 'relative',
    'paddingTop': 0,
    'paddingBottom': 0,
    'paddingLeft': 0,
    'paddingRight': 0,
    'transition': 'all 0.1s ease 0s',
    'backgroundColor': optionsBackgroundColor({
      isDisabled,
      isFocused,
      isSelected,
    }),
    'color': optionsColor({ isDisabled, isFocused }),
    'cursor': isDisabled ? 'not-allowed' : 'pointer',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && isSelected && 'var(--cui-bg-normal)',
      color: isSelected && 'var(--cui-bg-normal)',
    },
  }),
  placeholder: (defaultStyles: Record<string, unknown>) => ({
    ...defaultStyles,
    color: 'var(--cui-fg-subtle)',
    width: '-webkit-fill-available',
  }),
});
