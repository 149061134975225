import Script from 'next/script';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { initAnalytics } from './analytics';

export const GtmScript = (): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    initAnalytics({
      locale: router.locale,
    });
  }, [router.locale]);

  return (
    <>
      <script
        id="gtmCookieConsent"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html:
            // The backslash is valid, applying Prettier's suggestion would break it.
            /* eslint-disable-next-line no-useless-escape, prettier/prettier */ /* prettier-ignore */
            'function getCategories(){try{var e=decodeURIComponent(document.cookie);return/groups=([C\\d\\:\\,]+)/.exec(e)[1].split(",").reduce(function(e,t){var a=t.split(":");return e[a[0]]=a[1],e},{})}catch(e){return{C0001:"1"}}}function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[];var categories=getCategories();gtag("consent","default",{analytics_storage:"1"===categories.C0002?"granted":"denied",ad_storage:"1"===categories.C0004?"granted":"denied"}),"1"!==categories.C0004&&gtag("set","ads_data_redaction",!0);',
        }}
      />
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-ZC88');`,
        }}
      />

      <noscript>
        <iframe
          title="gtm"
          src={'https://www.googletagmanager.com/ns.html?id=GTM-ZC88'}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};
