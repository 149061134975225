import React, { FC } from 'react';
import { chunk } from 'lodash/fp';

import {
  PrimaryLinksList,
  PrimaryLinksItem,
  StyledAnchor,
  PrimaryLinksContainer,
} from '../styled';
import { FooterData } from '../../FooterTypes';
import { parseFooterDataUrls } from '../../FooterServices';

type Props = {
  footerData: FooterData;
  locale: string;
};

const PrimaryLinks: FC<Props> = ({ footerData, locale }) => {
  const primaryColumnsNumber = 3;
  const groupedPrimaryLinks = chunk(
    primaryColumnsNumber,
    footerData.fields.primaryLinks,
  );

  return (
    <PrimaryLinksContainer>
      {groupedPrimaryLinks.map((linksArr, chunkIndex) => (
        <PrimaryLinksList key={chunkIndex} colNumber={primaryColumnsNumber}>
          {linksArr.map((link, linkIndex) => (
            <PrimaryLinksItem key={linkIndex}>
              <StyledAnchor
                href={parseFooterDataUrls({ url: link.url, locale })}
                size="one"
              >
                {link.label}
              </StyledAnchor>
            </PrimaryLinksItem>
          ))}
        </PrimaryLinksList>
      ))}
    </PrimaryLinksContainer>
  );
};

export default PrimaryLinks;
