import React, { FC } from 'react';
import { Entry } from 'contentful';

import FooterTop from './components/FooterTop';
import FooterBottom from './components/FooterBottom';
import { FooterContainer } from './components/styled';
import { FooterData } from './FooterTypes';

import { ContentfulCountry } from 'types/common';

type FooterProps = {
  footerData: FooterData;
  countriesData: Array<Entry<ContentfulCountry>>;
  locale: string;
};

const Footer: FC<FooterProps> = ({ footerData, countriesData, locale }) =>
  footerData ? (
    <FooterContainer>
      <FooterTop
        locale={locale}
        footerData={footerData}
        countriesData={countriesData}
      />
      <FooterBottom locale={locale} footerData={footerData} />
    </FooterContainer>
  ) : null;

export default Footer;
