import { NextRouter, Router } from 'next/router';

import { removeTrailingSlash } from './common';

import { isPreviewDeployment } from 'server/util/isPreviewDeployment';

export function getRedirectURI(): string {
  // The redirect URL for SSO needs to be white-listed in Hydra. Vercel’s
  // preview deployments have unique (sub)domains but Hydra doesn’t support
  // wildcard domains. We redirect to a proxy with a stable domain that
  // redirects back to the preview deployment.
  if (isPreviewDeployment()) {
    return process.env.NEXT_PUBLIC_OIDC_PROXY;
  }

  // Handle callback on the client.
  return process.env.NEXT_PUBLIC_OIDC_CLIENT_REDIRECT_URI;
}

export const formatRedirectUrlFromRouter = (
  router: Router | NextRouter,
): string =>
  `/${router.locale}${removeTrailingSlash(router.asPath.split('?')[0])}`;
