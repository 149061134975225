import {
  getOptimizelyAnonymousUser,
  optimizelyClient,
} from 'src/services/optimizely/client';

const makeOptimizelyDecision = (experimentKey: string, country: string) => {
  const user = getOptimizelyAnonymousUser(country);
  return optimizelyClient.decide(experimentKey, null, user.id, user.attributes);
};

const isNewSupportCenterEnabled = (locale: string): boolean => {
  const country = locale.split('-')[1];
  const experimentKey = '_support_centre__multi-product_sc';
  const variationKey = makeOptimizelyDecision(experimentKey, country);
  return variationKey.enabled;
};

export { makeOptimizelyDecision, isNewSupportCenterEnabled };
