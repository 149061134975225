import React, { useEffect, useState } from 'react';
import { ReactSDKClient, withOptimizely } from '@optimizely/react-sdk';
import { useRouter } from 'next/router';

import { useUser } from 'providers/User';
import { getOptimizelyUser } from 'services/optimizely/client';

const OptimizelyEvents = ({ optimizely }: { optimizely: ReactSDKClient }) => {
  const [userEvent, setUserEvent] = useState(true);
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (user && userEvent && optimizely) {
      const optimizelyUser = getOptimizelyUser({ user, locale: router.locale });
      optimizely?.track(
        'support_centre_login',
        optimizelyUser.id,
        optimizelyUser.attributes,
        {
          Merchant_Code: user?.merchant_profile?.merchant_code,
          locale: router.locale,
        },
      );
      setUserEvent(false);
    }
  }, [optimizely, router.locale, user, userEvent]);

  return <></>;
};

export default withOptimizely(OptimizelyEvents);
