import { Entry } from 'contentful';
import { get } from 'lodash/fp';

import {
  DEFAULT_LOCALES,
  ZENDESK_LOCALES_TO_APP,
} from 'constants/localization';
import { APP_DOMAINS } from 'constants/common';
import { ContentfulCountry, ContentfulLanguage } from 'types/common';

type LocalisedTranslationData = {
  _nextI18Next?: {
    initialI18nStore: {
      [key: string]: {
        common: {
          meta: {
            title: string;
            description: string;
          };
        };
      };
    };
  };
};

export const parseLocaleToUpperCaseCountry = (locale: string): string => {
  if (
    locale === DEFAULT_LOCALES.SMARTLING ||
    locale === DEFAULT_LOCALES.ZENDESK
  ) {
    return DEFAULT_LOCALES.CONTENTFUL;
  }
  if (locale.includes('-')) {
    const splitLocale = locale.split('-');
    splitLocale[1] = splitLocale[1].toUpperCase();
    return splitLocale.join('-');
  }
  return locale;
};

export const parseLocaleToLowerCaseCountry = (locale: string): string => {
  if (locale === DEFAULT_LOCALES.SMARTLING) {
    return DEFAULT_LOCALES.CONTENTFUL;
  }
  if (locale.includes('-')) {
    const splitLocale = locale.split('-');
    splitLocale[1] = splitLocale[1].toLowerCase();
    return splitLocale.join('-');
  }
  return locale;
};

export const getCountryFromLocale = (locale: string): string =>
  locale.includes('-') ? locale.split('-')[1] : locale;

export const getLanguageFromLocale = (locale: string): string =>
  locale.includes('-') ? locale.split('-')[0] : locale;

export const metaTitleInTranslation = (
  locale: string,
  localisedTranslationData: LocalisedTranslationData,
): {
  title: string;
  description: string;
} => {
  const localisedMetaData =
    // eslint-disable-next-line no-underscore-dangle
    localisedTranslationData._nextI18Next.initialI18nStore[locale].common.meta;

  return {
    ...localisedMetaData,
  };
};

export const makeLocaleFromCountryAndLanguage = ({
  country = '',
  language = '',
}: {
  country: string;
  language: string;
}): string => `${language.toLowerCase()}-${country.toUpperCase()}`;

export const makeSCUrlFromLocale = ({
  country,
  language,
  oldSCURL,
  enabledLocales,
}: {
  country: string;
  language: string;
  oldSCURL: string;
  enabledLocales: string[];
}): {
  locale?: string;
  url?: string;
} => {
  const locale = makeLocaleFromCountryAndLanguage({ country, language });
  if (enabledLocales.includes(locale)) {
    return { locale };
  }
  return { url: oldSCURL };
};

export const isCountryInEnabledLocales = (
  countryCode: string,
  enabledLocales: string[],
): string | undefined =>
  enabledLocales.find((locale) => locale.includes(countryCode));

export const isLocaleInList = ({
  locale,
  enabledLocales,
}: {
  locale: string;
  enabledLocales: string[];
}): boolean => enabledLocales.includes(locale);

export const findUserValidLocale = ({
  userLanguage,
  userCountry,
  appLocales,
}: {
  userLanguage: string;
  userCountry: string;
  appLocales: string[];
}): string => {
  const userLocale = makeLocaleFromCountryAndLanguage({
    country: userCountry,
    language: userLanguage,
  });
  if (
    isLocaleInList({
      locale: userLocale,
      enabledLocales: appLocales,
    })
  ) {
    return userLocale;
  }
  return isCountryInEnabledLocales(userCountry, appLocales);
};

export const makeOldSCURLFromLocale = ({
  language,
  country,
}: {
  language: string;
  country: string;
}): string =>
  `${
    APP_DOMAINS.default
  }/hc/${language.toLowerCase()}-${country.toLowerCase()}`;

export const getCountryDataForLocale = ({
  locale,
  countriesData,
}: {
  locale: string;
  countriesData: Entry<ContentfulCountry>[];
}): Entry<ContentfulCountry> | undefined =>
  countriesData &&
  locale &&
  countriesData.find((item) =>
    locale.includes(get('fields.twoLetterIsoCode', item) as string),
  );

export const getLanguageDataForLocale = ({
  locale,
  countryData,
}: {
  locale: string;
  countryData: Entry<ContentfulCountry>;
}): Entry<ContentfulLanguage> => {
  const languageCode = getLanguageFromLocale(locale).toUpperCase();

  return (
    countryData &&
    locale &&
    countryData.fields.supportedLanguages.find(
      (language) => language.fields.twoLetterIsoCode === languageCode,
    )
  );
};

export const checkIfLocaleIsDoubled = (locale: string): string | undefined =>
  Object.keys(ZENDESK_LOCALES_TO_APP).find((zdLocale) => {
    if (
      !zdLocale.includes('-') &&
      locale.includes(zdLocale) &&
      locale === `${zdLocale}-${zdLocale}`
    ) {
      return true;
    }
    return false;
  });
