import React, { FC } from 'react';
import { AuthProvider } from '@sumup/react-nanoauth';

import { getRedirectURI } from 'src/services/authClient';
import { Children } from 'types/common';

type WithAuthProps = {
  children: Children;
};

export const WithAuth: FC<WithAuthProps> = ({ children }) => (
  <AuthProvider
    oidcConfig={{
      issuer: process.env.NEXT_PUBLIC_OIDC_ISSUER_URL,
      clientId: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
      scopes: process.env.NEXT_PUBLIC_OIDC_CLIENT_SCOPES.split(','),
      redirectURI: getRedirectURI(),
    }}
  >
    {children}
  </AuthProvider>
);
