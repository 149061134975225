import { useEffect, useState, useContext } from 'react';
import { AuthState, useAuth } from '@sumup/react-nanoauth';
import { useRouter } from 'next/router';

import { LOGIN_TRIED } from 'constants/storage';
import { isPreviewDeployment } from 'server/util/isPreviewDeployment';
import { formatRedirectUrlFromRouter } from 'services/authClient';
import { checkQueryInRouter, isWebCrawler } from 'services/common';
import logger from 'services/logger';
import { getJsonFromSession, setJsonToSession } from 'services/storage';
import { AppContext } from 'src/context/AppContext';

const AuthPersist = ({ children }): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  const auth = useAuth();
  const router = useRouter();

  const [persistTried, setPersistTried] = useState<boolean>(
    auth.authState === AuthState.Authenticated ||
      !!getJsonFromSession(LOGIN_TRIED) ||
      !!checkQueryInRouter(router, 'login_tried'),
  );

  useEffect(() => {
    if (getJsonFromSession('USER_AUTHENTICATED')) {
      dispatch({
        type: 'SET_AUTHENTICATED',
        payload: true,
      });
    }

    if (
      !persistTried &&
      !isWebCrawler() &&
      !checkQueryInRouter(router, 'code')
    ) {
      auth
        .authRedirect(
          {
            redirectRoute: formatRedirectUrlFromRouter(router),
            ...(isPreviewDeployment()
              ? { sourceOrigin: window?.location?.origin }
              : {}),
          },
          { prompt: 'none' },
        )
        .then(() => {
          setPersistTried(true);
          setJsonToSession(LOGIN_TRIED, true);
        })
        .catch((err: Error) => logger.error(err, 'Auth redirect failed'));
      return () => {};
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistTried, auth.authState]);

  useEffect(() => {
    if (checkQueryInRouter(router, 'consent_required')) {
      auth
        .authRedirect({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .then(() => {})
        .catch(() => {});

      return () => {};
    }

    if (
      checkQueryInRouter(router, 'code') &&
      (auth.authState === AuthState.Initializing ||
        auth.authState !== AuthState.Authenticated)
    ) {
      auth
        .handleCallback()
        .then(() => {
          setJsonToSession(LOGIN_TRIED, true);
          setJsonToSession('USER_AUTHENTICATED', true);
          dispatch({
            type: 'SET_AUTHENTICATED',
            payload: true,
          });
        })
        .catch((authErr: Error) =>
          logger.error(authErr, 'Auth handleCallback failed'),
        );
      return () => {};
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default AuthPersist;
