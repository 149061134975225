import env from 'services/env';

const SEARCH_ENDPOINT = '/api/articles';

export const SEARCH_ENDPOINT_URL = `${env(
  'NEXT_PUBLIC_SEARCH_SERVICE_ENDPOINT',
)}${SEARCH_ENDPOINT}`;

export const INITIAL_PAGE = 1;
export const RESULTS_PER_PAGE_SEARCH_BAR = 3;
export const RESULTS_PER_PAGE_SEARCH_RESULTS_PAGE = {
  INITIAL: 20,
  ON_SCROLL: 10,
};
export const MIN_LENGTH_OF_SEARCH_TERM = 3;

export const EMPTY_SEARCH_RESULTS = {
  articles: [],
  meta: {
    next_page_url: '',
    prev_page_url: '',
    total_articles: 0,
    current_page: 0,
    page_size: 0,
    total_pages: 0,
  },
};

export const MAX_CONTAINER_WIDTH_PX = 680;
export const MAX_SEARCH_WIDTH_PX = 500;

export const SEARCH = 'search';
