import { Entry } from 'contentful';

import { ContentfulCountry } from 'src/types/common';
import { CustomLiveAgentConfig } from 'src/types/context';

export const setJsonToSession = (
  key: string,
  value:
    | { [key: string]: string }
    | boolean
    | string
    | {
        locale: string;
        env: string;
        countryData: Entry<ContentfulCountry>;
        articleId: string;
      },
): void => window.sessionStorage.setItem(key, JSON.stringify(value));

export const getJsonFromSession = (
  key: string,
): Record<string, unknown> | boolean =>
  typeof window !== 'undefined' &&
  (JSON.parse(window.sessionStorage.getItem(key)) as { [key: string]: string });

export const removeJsonFromSession = (key: string): void =>
  typeof window !== 'undefined' && sessionStorage.removeItem(key);

export const setJsonToLocal = (
  key: string,
  value: { [key: string]: string } | boolean | string | CustomLiveAgentConfig,
): void => window.localStorage.setItem(key, JSON.stringify(value));

export const getJsonFromLocal = (key: string): { [key: string]: string } =>
  typeof window !== 'undefined' &&
  (JSON.parse(window.localStorage.getItem(key)) as { [key: string]: string });

export const removeJsonFromLocale = (key: string): void =>
  typeof window !== 'undefined' && localStorage.removeItem(key);

export const setCookie = ({
  name,
  value,
  exdays,
}: {
  name: string;
  value: string;
  exdays: number;
}): void => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};
