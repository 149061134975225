import { Entry } from 'contentful';
import React, {
  createContext,
  useReducer,
  Dispatch,
  ReactNode,
  useMemo,
} from 'react';

import {
  ContentfulErrorPage,
  ContentfulFooter,
  ContentfulHeader,
  ContentfulIntroCard,
  ContentfulProduct,
  ContentfulUserLandingPage,
} from 'src/types/common';

interface AppState {
  authenticated: boolean;
  header: Entry<ContentfulHeader>;
  userLandingPage: Entry<ContentfulUserLandingPage>[];
  products: Entry<ContentfulProduct>[];
  currentUserCategories: string;
  user: object;
  currentProduct: ContentfulIntroCard['title'];
  errorPage: Entry<ContentfulErrorPage>;
  footer: Entry<ContentfulFooter>;
  searchOverlay: boolean;
}

type AppAction =
  | { type: 'SET_AUTHENTICATED'; payload: boolean }
  | { type: 'UPDATE_PRODUCTS'; payload: Entry<ContentfulProduct>[] }
  | { type: 'SET_USER_CATEGORY'; payload: string }
  | {
      type: 'SET_USER_LANDING_PAGE';
      payload: Entry<ContentfulUserLandingPage>[];
    }
  | { type: 'USER_CATEOGIRES'; payload: Entry<ContentfulHeader> }
  | { type: 'SET_CURRENT_PRODUCT'; payload: ContentfulIntroCard['title'] }
  | { type: 'SET_USER'; payload: object }
  | { type: 'SET_ERROR_PAGE'; payload: Entry<ContentfulErrorPage> }
  | { type: 'SET_FOOTER'; payload: Entry<ContentfulFooter> }
  | { type: 'SET_SEARCH_OVERLAY'; payload: boolean };

const initialState: AppState = {
  currentUserCategories: '',
  authenticated: false,
  user: {},
  errorPage: null,
  products: [],
  header: null,
  userLandingPage: [],
  currentProduct: '',
  footer: null,
  searchOverlay: false,
};

const AppContext =
  createContext<{ state: AppState; dispatch: Dispatch<AppAction> } | undefined>(
    undefined,
  );

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'SET_USER_CATEGORY':
      return { ...state, currentUserCategories: action.payload };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.payload };
    case 'UPDATE_PRODUCTS':
      return { ...state, products: action.payload };
    case 'USER_CATEOGIRES':
      return { ...state, header: action.payload };
    case 'SET_USER_LANDING_PAGE':
      return { ...state, userLandingPage: action.payload };
    case 'SET_CURRENT_PRODUCT':
      return { ...state, currentProduct: action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_ERROR_PAGE':
      return { ...state, errorPage: action.payload };
    case 'SET_FOOTER':
      return { ...state, footer: action.payload };
    case 'SET_SEARCH_OVERLAY':
      return { ...state, searchOverlay: action.payload };
    default:
      return state;
  }
};

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const memoizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>
  );
};
export { AppContext, AppProvider };
