export const OIDC_COOKIES = {
  Token: 'oidc:token',
  RefreshToken: 'oidc:refresh_token',
  Claims: 'oidc-claims',
  CodeVerifier: 'oidc:code_verifier',
  Enabled: 'oidc:enabled',
  NewSession: 'oidc-new-session',
  // backwards compatibility for nanoauth
  AlreadyLoggedIn: 'logged_in',
};

// Setting up the cookie domain only for live. Otherwise we can't test locally
export const OPTIMIZELY_COOKIE_DOMAIN =
  process.env.NEXT_PUBLIC_ENV === 'production' ? '.sumup.com' : '';

export const AUTH_COOKIES = {
  DidLogin: 'did_login',
};

export const ONETRUST_CONSENT_COOKIE = 'OptanonConsent';

export const GEOLOCATION_COOKIES = {
  ORIGIN_COUNTRY: 'originCountry',
  HIDE_GEOLOCATION_MODAL: 'hideGeoLocationModal',
};

export const OPTIMIZELY_USER_COOKIE = 'optimizely_experiment_user';

/**
 * For use with the `Domain` cookie option. Includes the current top-level
 * domain and its subdomains.
 */
export const DOMAIN_INCLUDING_SUBDOMAINS =
  process.env.NEXT_PUBLIC_TLD && `.${process.env.NEXT_PUBLIC_TLD}`;
