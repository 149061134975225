import React from 'react';
import { OptimizelyProvider as SDKProvider } from '@optimizely/react-sdk';
import { useRouter } from 'next/router';

import { useUser } from '../User';

// import globalContext from 'components/GlobalContext';
import { Children } from 'types/common';
import {
  getOptimizelyUser,
  optimizelyClient,
} from 'services/optimizely/client';

const OptimizelyProvider = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  // const { authenticated } = useContext(globalContext);
  const { user } = useUser();
  const router = useRouter();
  return (
    <>
      <SDKProvider
        optimizely={optimizelyClient}
        user={getOptimizelyUser({
          user,
          locale: router.locale,
        })}
      >
        {children}
      </SDKProvider>
    </>
  );
};

export default OptimizelyProvider;
