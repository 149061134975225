import axios, { AxiosResponse } from 'axios';

import { SearchResults } from 'types/search';

type GetSearchResults = {
  page: number;
  size: number;
  locale: string;
  searchTerm: string;
};

export const getSearchResults = ({
  page,
  size,
  locale,
  searchTerm,
}: GetSearchResults): Promise<AxiosResponse<SearchResults>> =>
  axios.get(`/api/search/${searchTerm}`, {
    params: {
      page,
      size,
      locale,
    },
  });
