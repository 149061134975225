import { createContext } from 'react';

const globalContext = createContext({
  authenticated: false,
  setAuthenticated: (status) => {
    console.log(status);
  },
});

export default globalContext;
