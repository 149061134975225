import React, { useContext, useEffect, useState } from 'react';
import { Button, Hamburger, Popover, useModal } from '@sumup/circuit-ui';
import { Key, Logout, Search, Webspace } from '@sumup/icons';
import { Entry } from 'contentful';
import { useAuth } from '@sumup/react-nanoauth';
import { useRouter } from 'next/router';

import styles from './headerNavigation.module.css';

import { ContentfulHeader } from 'src/types/common';
import { formatRedirectUrlFromRouter } from 'src/services/authClient';
import { isPreviewDeployment } from 'src/server/util/isPreviewDeployment';
import { DASHBOARD_URL } from 'src/constants/common';
import { AppContext } from 'src/context/AppContext';
import LoggedInProfile from 'src/assets/icons/LoggedInProfile';
import LoggingOutProfile from 'src/assets/icons/LoggingOutProfile';

const SearchIcon = ({ handleOverlay }: { handleOverlay: () => void }) => (
  <div className={styles.searchIconWrapper}>
    <Search size="24" onClick={handleOverlay} />
  </div>
);

type HeaderNavigationProps = {
  headerData: Entry<ContentfulHeader>;
};

const HeaderNavigation = ({ headerData }: HeaderNavigationProps) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const { setModal } = useModal();
  const auth = useAuth();
  const router = useRouter();
  const { state, dispatch } = useContext(AppContext);

  const [login, setLogin] = useState<string>(undefined);

  useEffect(() => {
    if (headerData?.fields.logInButton?.fields?.cta) {
      setLogin(headerData.fields.logInButton.fields.cta);
    }
  }, [headerData]);

  const handleOverlay = () => {
    dispatch({ type: 'SET_SEARCH_OVERLAY', payload: true });
  };

  // Mobile Modal (Unauthenticated state)
  useEffect(() => {
    if (modalActive) {
      setModal({
        children: (
          <div className={styles.loginModalContainer}>
            <div className={styles.loginModalIcon}>
              <Key />
            </div>
            <div>Log in for a more personalised support experience.</div>
            <Button
              variant="primary"
              stretch={true}
              onClick={() =>
                auth.authRedirect({
                  redirectRoute: formatRedirectUrlFromRouter(router),
                  ...(isPreviewDeployment()
                    ? { sourceOrigin: window?.location?.origin }
                    : {}),
                })
              }
            >
              Login
            </Button>
          </div>
        ),
        onClose: () => setModalActive(false),
        closeButtonLabel: 'Close',
      });
    }
  }, [state.authenticated, modalActive, setModal, auth, router]);

  // Authenticated state
  if (state.authenticated) {
    return (
      <div className={styles.profileAndSearchWrapper}>
        <SearchIcon handleOverlay={handleOverlay} />
        <Popover
          placement="bottom"
          className={styles.popover}
          actions={[
            {
              icon: Webspace,
              children: 'Dashboard',
              onClick: () => window.open(DASHBOARD_URL, '_blank'),
            },
            {
              type: 'divider',
            },
            {
              children: 'Sign Out',
              icon: Logout,
              onClick: () => auth.endOIDCSession(),
            },
          ]}
          component={(popoverProps) => (
            <div className={styles.profile} {...popoverProps}>
              {popoverActive ? <LoggingOutProfile /> : <LoggedInProfile />}
            </div>
          )}
          isOpen={popoverActive}
          offset={{ mainAxis: 15, crossAxis: -45 }}
          onToggle={setPopoverActive}
        />
      </div>
    );
  }

  // Unauthenticated state
  return (
    <div className={styles.container}>
      <div className={styles.profileAndSearchWrapper}>
        <SearchIcon handleOverlay={handleOverlay} />
        {/* Desktop Login */}
        {login && (
          <Button
            className={styles.loginButton}
            variant="primary"
            size="m"
            onClick={() =>
              auth.authRedirect({
                redirectRoute: formatRedirectUrlFromRouter(router),
                ...(isPreviewDeployment()
                  ? { sourceOrigin: window?.location?.origin }
                  : {}),
              })
            }
          >
            {login}
          </Button>
        )}
      </div>
      {/* Mobile Login */}
      <Hamburger
        activeLabel="login"
        inactiveLabel="signup"
        className={styles.hamburger}
        onClick={() => setModalActive(!modalActive)}
      />
    </div>
  );
};

export default HeaderNavigation;
