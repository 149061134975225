const LoggingOutProfile = () => (
  <svg
    width="80"
    height="40"
    viewBox="0 0 80 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="80" height="40" rx="20" fill="#E3E7EB" />
    <path
      d="M32.71 27.29C32.8959 27.4796 33 27.7345 33 28C33 28.2652 32.8946 28.5196 32.7071 28.7071C32.5196 28.8946 32.2652 29 32 29C31.7345 29 31.4796 28.8959 31.29 28.71L24 21.42L16.71 28.71C16.5204 28.8959 16.2655 29 16 29C15.7348 29 15.4804 28.8946 15.2929 28.7071C15.1054 28.5196 15 28.2652 15 28C15 27.7345 15.1041 27.4796 15.29 27.29L22.58 20L15.29 12.71C15.1041 12.5204 15 12.2655 15 12C15 11.7348 15.1054 11.4804 15.2929 11.2929C15.4804 11.1054 15.7348 11 16 11C16.2655 11 16.5204 11.1041 16.71 11.29L24 18.58L31.29 11.29C31.4796 11.1041 31.7345 11 32 11C32.2652 11 32.5196 11.1054 32.7071 11.2929C32.8946 11.4804 33 11.7348 33 12C33 12.2655 32.8959 12.5204 32.71 12.71L25.42 20L32.71 27.29Z"
      fill="#0F131A"
    />
    <rect x="44" y="4" width="32" height="32" rx="16" fill="white" />
    <g clipPath="url(#clip0_534_26501)">
      <path
        d="M67.3211 26.146C67.028 24.4197 66.1308 22.8539 64.7898 21.7279C63.4489 20.602 61.7514 19.9893 60.0004 19.9993C58.2495 19.9893 56.552 20.602 55.211 21.7279C53.87 22.8539 52.9729 24.4197 52.6797 26.146C52.6603 26.2952 52.6731 26.4469 52.7173 26.5908C52.7615 26.7346 52.8361 26.8673 52.936 26.9798C53.036 27.0923 53.1589 27.1821 53.2965 27.2429C53.4342 27.3038 53.5833 27.3344 53.7337 27.3327H66.2671C66.4176 27.3344 66.5667 27.3038 66.7043 27.2429C66.8419 27.1821 66.9649 27.0923 67.0648 26.9798C67.1647 26.8673 67.2393 26.7346 67.2836 26.5908C67.3278 26.4469 67.3406 26.2952 67.3211 26.146ZM60.0004 24.9993C59.8026 24.9993 59.6093 24.9407 59.4448 24.8308C59.2804 24.7209 59.1522 24.5648 59.0765 24.382C59.0008 24.1993 58.981 23.9982 59.0196 23.8043C59.0582 23.6103 59.1535 23.4321 59.2933 23.2922C59.4332 23.1524 59.6113 23.0571 59.8053 23.0186C59.9993 22.98 60.2004 22.9998 60.3831 23.0755C60.5658 23.1512 60.722 23.2793 60.8319 23.4438C60.9418 23.6082 61.0004 23.8016 61.0004 23.9993C61.0004 24.2646 60.8951 24.5189 60.7075 24.7065C60.52 24.894 60.2656 24.9993 60.0004 24.9993ZM63.0004 15.666C63.0004 16.2594 62.8245 16.8394 62.4948 17.3327C62.1652 17.8261 61.6966 18.2106 61.1485 18.4377C60.6003 18.6647 59.9971 18.7241 59.4151 18.6084C58.8332 18.4926 58.2987 18.2069 57.8791 17.7873C57.4595 17.3678 57.1738 16.8332 57.0581 16.2513C56.9423 15.6693 57.0017 15.0661 57.2288 14.518C57.4558 13.9698 57.8404 13.5013 58.3337 13.1716C58.8271 12.842 59.4071 12.666 60.0004 12.666C60.7961 12.666 61.5591 12.9821 62.1217 13.5447C62.6843 14.1073 63.0004 14.8704 63.0004 15.666Z"
        fill="#0F131A"
      />
    </g>
    <defs>
      <clipPath id="clip0_534_26501">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(52 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LoggingOutProfile;
