import React, {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, ClickEvent, IconButton, SearchInput } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { Close } from '@sumup/icons';

import Article from '../Article';

import styles from './searchOverlay.module.css';

import { SearchResults } from 'src/types/search';
import {
  EMPTY_SEARCH_RESULTS,
  INITIAL_PAGE,
  MIN_LENGTH_OF_SEARCH_TERM,
  RESULTS_PER_PAGE_SEARCH_BAR,
} from 'src/constants/search';
import { getSearchResults } from 'src/services/api/search';
import { AppContext } from 'src/context/AppContext';

const SearchOverlay: FC = () => {
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] =
    useState<SearchResults>(EMPTY_SEARCH_RESULTS);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const router = useRouter();
  const {
    state: { searchOverlay },
    dispatch,
  } = useContext(AppContext);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (query.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        getSearchResults({
          page: INITIAL_PAGE,
          size: RESULTS_PER_PAGE_SEARCH_BAR,
          locale: router.locale,
          searchTerm: query,
        })
          .then((searchResponse) => setSearchResults(searchResponse.data))
          .catch(() => {});
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [query, router.locale]);

  useEffect(() => {
    if (searchResults.articles?.length > 0) {
      setShowSearchResults(true);
    }
  }, [searchResults]);

  useEffect(() => {
    document.documentElement.style.overflow = searchOverlay ? 'hidden' : 'auto';
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, [searchOverlay]);

  const searchRef = useRef(null);

  const handleTouch = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    searchRef?.current?.focus();
  };

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      if (query.length) {
        router.push(`/search/${query}`).catch(() => {});
        setShowSearchResults(false);
      }
    }
  };

  const handleClear = (event: TouchEvent | ClickEvent) => {
    if (event.type === 'touchstart' || event.type === 'click') {
      setQuery('');
      setShowSearchResults(false);
    }
  };

  return (
    <div className={searchOverlay ? styles.overlay : styles.none}>
      <div className={styles.searchOverlay}>
        <SearchInput
          hideLabel
          label="Search"
          placeholder="Search for answers"
          className={styles.search}
          inputClassName={styles.inputSearch}
          value={query}
          onChange={handleInputChange}
          onClear={handleClear}
          clearLabel="Reset"
          onTouchStart={handleTouch}
          onKeyUp={handleKeyPress}
          ref={searchRef}
          // We allow autofocus here because the overlay is only shown when the user clicks on the search icon
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <IconButton
          variant={'secondary'}
          icon={Close}
          onClick={() =>
            dispatch({ type: 'SET_SEARCH_OVERLAY', payload: false })
          }
        >
          Close
        </IconButton>
      </div>
      {showSearchResults ? (
        <div className={styles.searchResultsContainer}>
          {/* Search results are currently disabled on mobile */}
          <div className={`${styles.searchResults} ${styles.none}`}>
            {searchResults?.articles.map((article) => (
              <div key={article.id}>
                <Article
                  article={article}
                  onClick={() => {
                    setShowSearchResults(false);
                  }}
                  isMobile={true}
                />
              </div>
            ))}
          </div>
          <div className={styles.seeAllResultsButtonWrapper}>
            <Button
              variant="tertiary"
              onClick={() => router.push(`/search/${query}`)}
              onTouchStart={() => router.push(`/search/${query}`)}
            >
              See all results
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchOverlay;
