import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { SocialLink } from '../styled';
import { FooterDataFields } from '../../FooterTypes';

import SocialIcon from './components/SocialIcon';

import { StyledComponentsParams } from 'types/components';

const StyledSocialIcon = styled(SocialIcon)(
  ({ theme }: StyledComponentsParams) => css`
    color: var(--cui-fg-subtle);
    fill: var(--cui-fg-subtle);
    width: ${theme.iconSizes.kilo};
    height: ${theme.iconSizes.kilo};
    transition: all ${theme.transitions.default};
    &:hover,
    &:focus {
      color: var(--cui-bg-accent-strong);
      fill: var(--cui-bg-accent-strong);
      transform: scale(1.1);
    }
  `,
);

type Props = {
  socialLinks: FooterDataFields['socialLinks'];
};

const SocialIcons: FC<Props> = ({ socialLinks = [] }) => (
  <>
    {socialLinks.map((item, index) => (
      <SocialLink key={index} title={item.label} href={item.url}>
        <StyledSocialIcon name={item.label} />
      </SocialLink>
    ))}
  </>
);

export default SocialIcons;
