import { includes } from 'lodash/fp';

const getEnvFromUrl: (currentUrl: string) => string = (currentUrl) => {
  if (includes('help.sumup.com', currentUrl)) {
    return 'production';
  }
  if (includes('support-centre.sam-app.ro', currentUrl)) {
    return 'staging';
  }
  return 'development';
};

export default getEnvFromUrl;
