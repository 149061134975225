import * as contentful from 'contentful';

import {
  CONTENTFUL_CREDENTIALS,
  CONTENTFUL_ENTRIES_ROOT_URL,
  CONTENT_TYPES,
  EXISTS_FIELDS,
} from 'constants/contentful';
import { APP_DOMAINS } from 'constants/common';
import { DEFAULT_LOCALES } from 'constants/localization';
import {
  ContentfulArticle,
  ContentfulModalAllLocales,
  ContentfulSection,
} from 'types/common';

export const contentfulClient = contentful.createClient({
  space: CONTENTFUL_CREDENTIALS.SPACE,
  environment: CONTENTFUL_CREDENTIALS.ENV,
  accessToken: CONTENTFUL_CREDENTIALS.TOKEN_DELIVERY,
});

export const contentfulRevampClient = contentful.createClient({
  space: CONTENTFUL_CREDENTIALS.SPACE,
  environment: CONTENTFUL_CREDENTIALS.REVAMP_ENV,
  accessToken: CONTENTFUL_CREDENTIALS.TOKEN_DELIVERY,
});

export const contentfulPOSClient = contentful.createClient({
  space: CONTENTFUL_CREDENTIALS.SPACE,
  environment: CONTENTFUL_CREDENTIALS.ENV_POS,
  accessToken: CONTENTFUL_CREDENTIALS.TOKEN_DELIVERY,
});

export const contentfulPreviewClient = contentful.createClient({
  space: CONTENTFUL_CREDENTIALS.SPACE,
  environment: CONTENTFUL_CREDENTIALS.ENV,
  accessToken: CONTENTFUL_CREDENTIALS.TOKEN_PREVIEW,
  host: CONTENTFUL_CREDENTIALS.PREVIEW_HOST,
});

export const contentfulPOSPreviewClient = contentful.createClient({
  space: CONTENTFUL_CREDENTIALS.SPACE,
  environment: CONTENTFUL_CREDENTIALS.ENV_POS,
  accessToken: CONTENTFUL_CREDENTIALS.TOKEN_PREVIEW,
  host: CONTENTFUL_CREDENTIALS.PREVIEW_HOST,
});

export const makeContentfulEntryUrl = (entryId: string): string =>
  `${CONTENTFUL_ENTRIES_ROOT_URL}${entryId}`;

export const formatContentfulHref = (href: string): string => {
  if (
    href.includes(APP_DOMAINS.no_proto) &&
    !href.includes(DEFAULT_LOCALES.ZENDESK)
  ) {
    const urlParts = href.split('/');
    const { length } = urlParts;
    return `/${urlParts[length - 2]}/${urlParts[length - 1]}`;
  }
  return href;
};

export const determineHrefLocalePrepend = (
  href: string,
): { [key: string]: boolean } =>
  href.includes(DEFAULT_LOCALES.ZENDESK) ? { locale: false } : {};

export const findContentfulEntry = async ({
  contentType,
  locale,
  contentfulId,
}: {
  contentType: string;
  locale?: string;
  contentfulId: string;
}) => {
  const contentfulRes: contentful.EntryCollection<
    | ContentfulArticle['fields']
    | ContentfulSection['fields']
    | ContentfulModalAllLocales
  > = await contentfulClient.getEntries({
    'content_type': contentType,
    'sys.id': contentfulId,
    'include': 10,
    ...(locale ? { locale } : { locale: '*' }),
    ...(contentType === CONTENT_TYPES.ARTICLE ? EXISTS_FIELDS.ARTICLE : null),
    ...(contentType === CONTENT_TYPES.SECTION ? EXISTS_FIELDS.SECTION : null),
  });

  return contentfulRes?.items;
};
