import React, { FC } from 'react';
import { get } from 'lodash';

import {
  BottomLinksList,
  BottomLinksWrapper,
  Disclaimer,
  FooterWrapperBottom,
  SecondaryLinksItem,
  SecondaryLinksList,
  StyledAnchor,
} from '../styled';
import SocialIcons from '../SocialIcons';
import { FooterData } from '../../FooterTypes';
import { parseFooterDataUrls } from '../../FooterServices';

import { Col, Row } from 'components/TypedCircuit';
import { SmallGrid } from 'components/styledComponents';

type Props = {
  footerData: FooterData;
  locale: string;
};

type SecondaryLinksArray = Array<{
  url: string;
  label: string;
}>;

const Component: FC<Props> = ({ footerData, locale }) => (
  <FooterWrapperBottom>
    <SmallGrid>
      <Row>
        <Col span="12" skip="0">
          <BottomLinksWrapper>
            <BottomLinksList>
              <li>
                <SecondaryLinksList>
                  {(
                    get(
                      footerData,
                      'fields.secondaryLinks',
                      [],
                    ) as SecondaryLinksArray
                  ).map((linkData, linkIndex) => (
                    <SecondaryLinksItem key={linkIndex}>
                      <StyledAnchor
                        size="two"
                        href={parseFooterDataUrls({
                          url: linkData.url,
                          locale,
                        })}
                      >
                        {linkData.label}
                      </StyledAnchor>
                    </SecondaryLinksItem>
                  ))}
                </SecondaryLinksList>
              </li>
              <li>
                <SocialIcons socialLinks={footerData.fields.socialLinks} />
              </li>
            </BottomLinksList>
          </BottomLinksWrapper>
        </Col>
      </Row>
      <Row>
        <Col span="12" skip="0">
          <Disclaimer size="two">{footerData.fields.disclaimer}</Disclaimer>
        </Col>
      </Row>
    </SmallGrid>
  </FooterWrapperBottom>
);

export default Component;
