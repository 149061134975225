/* eslint-disable */
import { Ref } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Anchor, Card, Headline, Body } from '@sumup/circuit-ui';
import Image from 'next/image';

import { MAX_CONTAINER_WIDTH_PX } from 'constants/search';
import { StyledComponentsParams } from 'types/components';

export const AllResultsContainer = styled('div')(
  () => css`
    box-shadow: none;
    border: none;
  `,
);

export const MainContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    width: 80%;
    max-width: ${MAX_CONTAINER_WIDTH_PX}px;
    margin: auto;

    ${theme.mq.untilMega} {
      width: 100%;
    }
  `,
);

export const ResultContainer = styled(Card)(
  ({
    theme,
    inPage,
    withXPadding,
    nsc
  }: StyledComponentsParams & {
    withXPadding: boolean;
    inPage?: boolean;
    ref: Ref<Element>;
    nsc?: boolean;
  }) => css`
    align-items: flex-start;
    background-color: unset;
    border: none;
    border-radius: 0;
    gap: ${nsc ? '8px': 0};
    box-shadow: none;
    margin-top: ${nsc ? `10px` : 0};
    padding: ${withXPadding ? theme.spacings.kilo : `${theme.spacings.kilo} 0`};
    ${inPage &&
    `border-bottom: ${nsc ? 'none' : theme.borderWidth.kilo} solid var(--cui-bg-normal-pressed);`}
  `,
);

export const ResultsGreeting = styled(Headline)(
  ({ theme }: StyledComponentsParams) => css`
    margin-bottom: ${theme.spacings.tera};
  `,
);

export const ResultTitle = styled(Anchor)(
  ({ theme, noLinks, nsc }: StyledComponentsParams & { noLinks: boolean, nsc: boolean }) => css`
    color: var(--cui-bg-strong);
    text-decoration: ${nsc ? `underline`: `none`};

    ${!noLinks &&
    `&:hover,
    &:visited:hover {
      color: var(--cui-bg-accent-strong);
      text-decoration: none;
    }`}
    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
      text-decoration: none;
    }
  `,
);

export const ResultSectionsContainer = styled('div')(
  () => css`
    display: flex;
    flex-wrap: wrap;
  `,
);

export const SectionIcon = styled(Image)(() => css``);

export const SectionLink = styled(Anchor)(
  ({ theme, noLinks }: StyledComponentsParams & { noLinks: boolean }) => css`
    display: flex;
    color: var(--cui-bg-highlight-pressed);
    margin-top: ${theme.spacings.byte};
    margin-right: ${theme.spacings.mega};
    text-decoration: none;

    ${!noLinks &&
    `&:hover,
    &:visited:hover {
      text-decoration: underline;
      color: var(--cui-bg-highlight-pressed);
    }`}

    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-highlight-pressed);
    }

    & > div {
      width: unset;
    }
  `,
);

export const SectionTitle = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    margin-left: calc(${theme.spacings.kilo} / 2);
  `,
);
