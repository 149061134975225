export const EVENTS = {
  CONTENT_VIEW: 'content-view',
  INTERACTION: 'interaction',
};

export const PAGE_TYPES = {
  sections: 'sectionPage',
  articles: 'articlePage',
  LANDING_PAGE: 'landingPage',
  search: 'searchPage',
};

export const ACTIONS = {
  SEARCH: 'search',
  SELECT_FROM_DROPDOWN: 'support_articles_search_selected_item_dropdown',
  CLICK_SEE_ALL: 'clicked_see_all_from_dropdown',
  SELECT_FROM_RESULTS: 'support_articles_search_selected_item_results_page',
  LOGIN: 'login',
  DISMISS_SUPPORT_HERO: 'dimsmiss@support_hero',
  CTA_SUPPORT_HERO: 'cta@support_hero',
};

export const TARGETS = {
  SUPPORT_CENTRE: 'customer_support_centre',
};

export const TARGET_PROPERTIES = {
  PAYOUTS: 'payouts',
};
