import React, { FC, forwardRef } from 'react';
import Link from 'next/link';
import { Entry } from 'contentful';

import CountryPicker from '../CountryPicker';
import { FooterLogo, FooterWrapperTop, LogoLink, LogoWrapper } from '../styled';
import { FooterData } from '../../FooterTypes';
import PrimaryLinks from '../PrimaryLinks';

import { Col, Row } from 'components/TypedCircuit';
import { SmallGrid } from 'components/styledComponents';
import { ContentfulCountry } from 'types/common';

type Props = {
  footerData: FooterData;
  countriesData: Array<Entry<ContentfulCountry>>;
  locale: string;
};

const ForwardedLogoLink =
  // eslint-disable-next-line prefer-arrow-callback
  forwardRef(function CustomLogoLink(
    { href }: { href?: string },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    _ref,
  ) {
    return (
      <LogoLink href={href} aria-label="SumUp Logo">
        <FooterLogo />
      </LogoLink>
    );
  });

const Component: FC<Props> = ({ footerData, countriesData, locale }) => (
  <FooterWrapperTop>
    <SmallGrid>
      <Row>
        <Col span="12" skip="0">
          <LogoWrapper>
            <Link passHref href="/">
              <ForwardedLogoLink />
            </Link>
          </LogoWrapper>
        </Col>
      </Row>
      <Row>
        <Col span={{ default: 12, mega: 9, kilo: 12 }} skip="0">
          <PrimaryLinks locale={locale} footerData={footerData} />
        </Col>
        <Col span={{ default: 12, mega: 3, kilo: 12 }} skip="0">
          <CountryPicker countriesData={countriesData} />
        </Col>
      </Row>
    </SmallGrid>
  </FooterWrapperTop>
);

export default Component;
