import { Facebook, Twitter, Instagram, Youtube, Linkedin } from '@sumup/icons';

import { ContentfulFooterData } from 'src/types/common';

const mapIconsToLabel = (label: string) => {
  switch (label) {
    case 'facebook':
      return <Facebook />;
    case 'linkedin':
      return <Linkedin />;
    case 'twitter':
      return <Twitter />;
    case 'youtube':
      return <Youtube />;
    case 'instagram':
      return <Instagram />;
    default:
      return null;
  }
};

const SUMUP = 'https://sumup.co.uk';

const generateLinks = (url: string) => {
  if (url.startsWith('https')) {
    return url;
  }
  return `${SUMUP}/${url}`;
};

function groupByThree(
  arr: Array<ContentfulFooterData>,
): Array<Array<ContentfulFooterData>> {
  return arr.reduce(
    (
      acc: Array<Array<ContentfulFooterData>>,
      current: ContentfulFooterData,
      index: number,
    ) => {
      const groupIndex = Math.floor(index / 3);

      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }

      acc[groupIndex].push(current);
      return acc;
    },
    [],
  );
}

export { mapIconsToLabel, generateLinks, groupByThree };
