export const API_VERSION = 52;

export const SALESFORCE_API_ENDPOINT: {
  [key: string]: string;
} = {
  production: 'https://d.la1-core1.sfdc-yzvdd4.salesforceliveagent.com',
  preview: 'https://d.la1-c1cs-lo3.salesforceliveagent.com',
  development: 'https://d.la1-c1cs-lo3.salesforceliveagent.com',
};

export const AVAILABILITY_URL = '/chat/rest/Visitor/Availability';

export const SCRIPT_INJECTOR_CONFIG: {
  src: {
    production: string;
    preview: string;
    development: string;
  };
  async: boolean;
} = {
  src: {
    production: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    preview: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    development: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
  },
  async: true,
};

export const AVATAR_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRz';
export const WAITING_STATE_BACKGROUND_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRu';
export const SMALL_COMPANY_LOGO_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRp';

export const GSL_BASE_URL = {
  production: 'https://service.force.com',
  preview: 'https://service.force.com',
  development: 'https://service.force.com',
};
export const SALESFORCE_URL = {
  production: 'https://sumup.my.salesforce.com',
  preview: 'https://sumup--quality.sandbox.my.salesforce.com',
  development: 'https://sumup--quality.sandbox.my.salesforce.com',
};
export const SALESFORCE_CHAT_URL = {
  production: 'https://sumup.secure.force.com/Chat',
  preview: 'https://sumup--quality.sandbox.my.salesforce-sites.com/Chat',
  development: 'https://sumup--quality.sandbox.my.salesforce-sites.com/Chat',
};
export const ORG_ID = {
  production: '00DD0000000pnkQ',
  preview: '00D3G00000096mV',
  development: '00D3G00000096mV',
}; // ORG_ID
export const LIVE_AGENT_CONTENT_URL = {
  production: 'https://c.la1-c2-fra.salesforceliveagent.com/content',
  preview: 'https://c.la1-c1cs-lo3.salesforceliveagent.com/content',
  development: 'https://c.la1-c1cs-lo3.salesforceliveagent.com/content',
};
export const DEPLOYMENT_ID = '572D00000008Q3X';
export const LIVE_AGENT_URL = {
  production: 'https://d.la1-c2-fra.salesforceliveagent.com/chat',
  preview: 'https://d.la1-c1cs-lo3.salesforceliveagent.com/chat',
  development: 'https://d.la1-c1cs-lo3.salesforceliveagent.com/chat',
};
export const LIVE_AGENT_DEV_NAME =
  'EmbeddedServiceLiveAgent_Parent04I570000000006EAA_161715453d0';

export const SELECTORS_TO_BE_HIDDEN = [
  '.footerMenuWrapper',
  '.embeddedServiceLiveAgentStateChatUnreadMessageBanner',
];
export const BUTTON_IDS = {
  'de-AT': '5732p000000Csl1',
  'nl-BE': '5732p000000Csl2',
  'fr-BE': '5732p000000Csl3',
  'pt-BR': '5732p000000Csl4',
  'bg-BG': '5732p000000Csl5',
  'es-CL': '5732p000000Csl6',
  'es-CO': '5732p000000CslZ',
  'el-CY': '5732p000000Csl7',
  'cs-CZ': '5732p000000Csl8',
  'da-DK': '5732p000000Csl9',
  'et-EE': '5732p000000CslA',
  'fi-FI': '5732p000000CslB',
  'fr-FR': '5732p000000CslC',
  'de-DE': '5732p000000CslD',
  'el-GR': '5732p000000CslE',
  'hu-HU': '5732p000000CslF',
  'en-IE': '5732p000000CslG',
  'it-IT': '5732p000000CslH',
  'lv-LV': '5732p000000CslI',
  'lt-LT': '5732p000000CslJ',
  'fr-LU': '5732p000000CslK',
  'de-LU': '5732p000000CslL',
  'nl-NL': '5732p000000CslX',
  'nb-NO': '5732p000000CslN',
  'pl-PL': '5732p000000CslO',
  'pt-PT': '5732p000000CslP',
  'sk-SK': '5732p000000CslQ',
  'sl-SI': '5732p000000CslR',
  'es-ES': '5732p000000CslS',
  'sv-SE': '5732p000000CslT',
  'fr-CH': '5732p000000CslU',
  'de-CH': '5732p000000CslV',
  'it-CH': '5732p000000CslW',
  'en-GB': '5732p000000CslY',
  'en-US': '5732p000000CslZ',
  'es-US': '5732p000000CslZ',
};

export const CHAT_LOAD_STORAGE_KEY = 'loadChat';
export const CHAT_CONFIG_STORAGE_KEY = 'chatConfig';
export const CUSTOM_LIVE_AGENT = 'chatCustomConfig';

export const AXP_CONFIG = {
  SCRIPT_INJECTOR_CONFIG: {
    src: 'https://sumup--axp.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    async: true,
  },
  SALESFORCE_URL: 'https://sumup--axp.my.salesforce.com/',
  SALESFORCE_CHAT_URL:
    'https://sumup--axp.sandbox.my.salesforce-sites.com/Chat',
  GSL_BASE_URL: null,
  ORG_ID: '00D1l0000000whW',
  LIVE_AGENT_CONTENT_URL:
    'https://c.la3-c1cs-fra.salesforceliveagent.com/content',
  DEPLOYMENT_ID: '572D00000008Q3X',
  BUTTON_IDS: {
    IT: '5732p000000Csmn',
    DE: '5732p000000Csmd',
    FR: '5732p000000Csmi',
    UK: '573D0000000CcPv',
  },
  LIVE_AGENT_URL: 'https://d.la3-c1cs-fra.salesforceliveagent.com/chat',
  LIVE_AGENT_DEV_NAME:
    'EmbeddedServiceLiveAgent_Parent04I570000000006EAA_161715453d0',
  ENDPOINT: 'https://d.la1-c1cs-cdg.salesforceliveagent.com',
};

export const TEST_CONFIG = {
  SCRIPT_INJECTOR_CONFIG: {
    src: 'https://sumup.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    async: true,
  },
  SALESFORCE_URL: 'https://sumup.my.salesforce.com/',
  SALESFORCE_CHAT_URL: 'https://sumup.secure.force.com/SupportCenter',
  GSL_BASE_URL: 'https://service.force.com/',
  ORG_ID: '00DD0000000pnkQ',
  LIVE_AGENT_CONTENT_URL:
    'https://c.la1-c2-cdg.salesforceliveagent.com/content',
  DEPLOYMENT_ID: '572D00000008Q3X',
  BUTTON_IDS: {
    UK: '5732p000000CslY',
  },
  LIVE_AGENT_URL:
    'https://d.la1-core1.sfdc-yzvdd4.salesforceliveagent.com/chat',
  LIVE_AGENT_DEV_NAME:
    'EmbeddedServiceLiveAgent_Parent04I2p000000boYyEAI_17d99ef7a28',
  ENDPOINT: 'https://d.la1-c1cs-cdg.salesforceliveagent.com',
};

export const LIVE_AGENT_ENV: {
  [key: string]: string;
} = {
  QUALITY: 'Quality',
  AXP: 'AXP',
  PRODUCTION: 'production',
  TEST: 'test',
};
