import React, { FC, useEffect, useMemo, useState } from 'react';

import { PreviewToggleButton } from '../styledComponents';

const Component: FC<{ previewEnabled: boolean }> = ({ previewEnabled }) => {
  const [shouldShow, setShouldShow] = useState(false);
  const buttonHref = useMemo(
    () => (previewEnabled ? '/api/preview/disable' : '/api/preview/enable'),
    [previewEnabled],
  );
  const buttonText = useMemo(
    () => (previewEnabled ? 'Disable Preview' : 'Enable Preview'),
    [previewEnabled],
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.location.href.includes('sam-app.ro')) {
      setShouldShow(true);
    }
  }, [shouldShow]);

  return shouldShow ? (
    <PreviewToggleButton href={buttonHref}>{buttonText}</PreviewToggleButton>
  ) : null;
};

export default Component;
