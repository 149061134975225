import React, { FC, forwardRef } from 'react';
import { Entry } from 'contentful';
import { get } from 'lodash/fp';
import Link from 'next/link';
import { NextRouter } from 'next/router';

import { ContentfulCountry } from 'types/common';
import {
  HeaderCountryPickerOption,
  HeaderCountryPickerOptionContent,
  TabContent,
} from 'components/Header/components/styled';
import {
  makeOldSCURLFromLocale,
  makeSCUrlFromLocale,
} from 'services/localization';

type LanguageOptionsProps = {
  selectedCountryData: Entry<ContentfulCountry>;
  enabledLocales: string[];
  router: NextRouter;
  removeModal?: () => void;
};

// eslint-disable-next-line prefer-arrow-callback
const ForwardedOption = forwardRef(function CustomOption(
  {
    href,
    name,
    removeModal,
  }: { href?: string; name: string; removeModal: () => void },
  /* eslint-disable @typescript-eslint/no-unused-vars */
  _ref,
) {
  return (
    <HeaderCountryPickerOption onClick={removeModal} href={href} key={name}>
      <HeaderCountryPickerOptionContent>
        {name}
      </HeaderCountryPickerOptionContent>
    </HeaderCountryPickerOption>
  );
});

const TWO_LETTER_CODE_PATH = 'fields.twoLetterIsoCode';

const LanguageOptions: FC<LanguageOptionsProps> = ({
  enabledLocales,
  removeModal,
  router,
  selectedCountryData,
}) => (
  <TabContent>
    {selectedCountryData.fields.supportedLanguages.map((el) => {
      const url = makeSCUrlFromLocale({
        language: (get(TWO_LETTER_CODE_PATH, el) as string).toLowerCase(),
        country: get(TWO_LETTER_CODE_PATH, selectedCountryData) as string,
        oldSCURL: makeOldSCURLFromLocale({
          language: get(TWO_LETTER_CODE_PATH, el) as string,
          country: get(TWO_LETTER_CODE_PATH, selectedCountryData) as string,
        }),
        enabledLocales,
      });
      return (
        <Link
          key={el.fields.name}
          href={url.locale ? router.asPath : url.url}
          locale={url.locale ? url.locale : false}
          passHref
        >
          <ForwardedOption removeModal={removeModal} name={el.fields.name} />
        </Link>
      );
    })}
  </TabContent>
);

export default LanguageOptions;
