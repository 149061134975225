import type { IElbwalker, WebDestination } from '@elbwalker/walker.js';
import Elbwalker, { elb } from '@elbwalker/walker.js';

import AnalyticsEvents, { COMMON_PROPERTIES } from './analyticsEvents';

import logger from 'services/logger';
import {
  getOptimizelyUser,
  optimizelyClient,
} from 'services/optimizely/client';
import { User } from 'src/types/context';

export const sendOptimizelyEvent = ({
  user,
  event,
  tags,
  locale,
}: {
  user?: User;
  event: string;
  tags: Record<string, string>;
  locale: string;
}): void => {
  const optimizelyUser = getOptimizelyUser({ user, locale });
  optimizelyClient?.track(
    event,
    optimizelyUser.id,
    optimizelyUser.attributes,
    tags,
  );
};

export const destinationDataLayer: WebDestination.Function<never, never> = {
  config: {},

  init() {
    window.dataLayer = window.dataLayer || [];
    return true;
  },

  push(event) {
    const commonProperties = mapCommonEventProperties(event);

    const googleAnalyticsEvent = {
      ...commonProperties,
      ...event.data,
    };

    logger.debug('Analytics event:', googleAnalyticsEvent);

    window.dataLayer.push(googleAnalyticsEvent);
  },
};

function mapCommonEventProperties(event: IElbwalker.Event) {
  return {
    ...event.globals,
    event: event.event.replace(' ', '_').toLowerCase(),
    event_timestamp_msec: event.timestamp,
    walker: true,
  };
}

export function initAnalytics({
  locale,
  merchantCode,
  merchantCategoryCode,
  merchantCountryCode,
}: {
  locale: string;
  merchantCode?: string;
  merchantCategoryCode?: string;
  merchantCountryCode?: string;
}) {
  if (!window.elbwalker) {
    window.elbwalker = Elbwalker({
      globals: {
        app: COMMON_PROPERTIES.APP,
        user_agent: navigator.userAgent,
        html_language: locale?.toLowerCase(),
        merchant_code: merchantCode,
        merchant_category_code: merchantCategoryCode,
        merchant_country_code: merchantCountryCode,
      },
    });
  }

  elb('walker destination', destinationDataLayer);
}

export { AnalyticsEvents };
