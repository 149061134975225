import React, { FC, MouseEventHandler } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import {
  LinkToResultsPage,
  ResultLink,
  ResultWrapper,
  SuggestionsContainer,
} from './components/styled';

import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import Result from 'components/SearchResult';
import { SearchResults } from 'types/search';
import * as Analytics from 'services/analytics';
import { getCurrentPageName } from 'services/pages/common';

type ComponentProps = {
  results: SearchResults;
  searchTerm: string;
};

const SearchSuggestions: FC<ComponentProps> = ({ results, searchTerm }) => {
  const { t } = useTranslation();

  const enforceSelfClick: MouseEventHandler<HTMLParagraphElement> &
    MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = ({ target }) =>
    (target as HTMLAnchorElement).click();
  const router = useRouter();

  return (
    <SuggestionsContainer>
      {results.articles.map((resultData, index) =>
        index < 3 ? (
          <ResultWrapper key={resultData.id}>
            <Link
              passHref
              key={resultData.id}
              href={`/articles/${resultData.id}`}
            >
              <ResultLink
                onClick={() => {
                  Analytics.AnalyticsEvents.sendSearchEvent({
                    rank: index + 1,
                    query: searchTerm,
                    locale: router.locale,
                    event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
                    action: ANALYTICS_CONSTANTS.ACTIONS.SELECT_FROM_DROPDOWN,
                    pageName: getCurrentPageName(router),
                    article_id: resultData.id,
                  });
                  Analytics.AnalyticsEvents.sendButtonClick({
                    buttonDescription: 'article_selection',
                    option: 'dropdown',
                    value: 'classic',
                    searchQuery: searchTerm,
                    article_id: resultData.id,
                    locale: router.locale,
                  });
                }}
                onMouseDown={enforceSelfClick}
                key={resultData.id}
              >
                <Result
                  noLinks
                  withXPadding
                  key={resultData.id}
                  resultData={resultData}
                />
              </ResultLink>
            </Link>
          </ResultWrapper>
        ) : null,
      )}
      <ResultWrapper linkToResults>
        <Link passHref href={`/search/${searchTerm}`}>
          <LinkToResultsPage
            onClick={() => {
              Analytics.AnalyticsEvents.sendSearchEvent({
                query: searchTerm,
                event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
                action: ANALYTICS_CONSTANTS.ACTIONS.CLICK_SEE_ALL,
                pageName: getCurrentPageName(router),
                locale: router.locale,
              });
              Analytics.AnalyticsEvents.sendButtonClick({
                buttonDescription: 'search_results_opening',
                locale: router.locale,
              });
            }}
            onMouseDown={enforceSelfClick}
            variant="highlight"
            size="one"
          >
            {`${t('search.see_all_results')} (${results.meta.total_articles})`}
          </LinkToResultsPage>
        </Link>
      </ResultWrapper>
    </SuggestionsContainer>
  );
};

export default SearchSuggestions;
