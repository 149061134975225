import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Anchor,
  Body,
  SearchInput,
  IconButton,
  Card,
  Tab,
  TabList,
  TabPanel,
} from '@sumup/circuit-ui';
import * as icons from '@sumup/icons';
import { Theme } from '@sumup/design-tokens';

import { MAX_SEARCH_WIDTH_PX } from 'constants/search';
import { StyledComponentsParams } from 'types/components';
import SumupLogo from 'src/assets/icons/SumupLogo';

const COUNTRY_PICKER_WIDTH = 426;
const COUNTRY_PICKER_HEIGHT = 308;
const COUNTRY_PICKER_BUTTON_WIDTH = 42;

export const CountryFlagContainer = styled(IconButton)(
  ({ theme }: StyledComponentsParams) => css`
    margin-left: ${theme.spacings.byte};
  `,
);

export const CountryFlag = ({
  flagName = '',
  ...props
}: {
  flagName: string;
  className?: string;
}) => {
  const StyledFlag =
    flagName &&
    styled(icons[flagName])(
      ({ theme }: StyledComponentsParams) => css`
        width: calc(${theme.spacings.mega} + ${theme.spacings.bit});
        height: calc(${theme.spacings.mega} + ${theme.spacings.bit});

        ${theme.mq.giga} {
          width: ${theme.spacings.tera};
          height: ${theme.spacings.giga};
        }
      `,
    );

  return flagName ? <StyledFlag {...props} /> : null;
};

export const Description = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    position: relative;
    z-index: ${theme.zIndex.input};
  `,
);

export const Greeting = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacings.tera};
    text-align: center;
    position: relative;
    z-index: ${theme.zIndex.popover};

    ${theme.mq.untilMega} {
      font-size: ${theme.typography.headline.two.fontSize};
      line-height: ${theme.typography.headline.two.lineHeight};
    }
    ${theme.mq.mega} {
      font-size: ${theme.typography.headline.one.fontSize};
      line-height: ${theme.typography.headline.one.lineHeight};
    }
  `,
);

const headerMargin = ({
  theme,
  isSearch,
  searchResults,
}: {
  theme: Theme;
  isSearch: boolean;
  searchResults: boolean;
}) => {
  if (isSearch && !searchResults) {
    return theme.spacings.mega;
  }
  return theme.spacings.zetta;
};

export const Header = styled('header')(
  ({
    theme,
    isPos = false,
    isSearch,
    searchResults,
  }: StyledComponentsParams & {
    isPos?: boolean;
    isSearch: boolean;
    searchResults: undefined | null | boolean;
  }) => css`
    margin-bottom: ${isPos
      ? theme.spacings.giga
      : headerMargin({ theme, isSearch, searchResults })};
    ${theme.mq.mega} {
      margin-top: calc(2 * ${theme.spacings.tera});
    }
    position: relative;
  `,
);

export const PosGreetingCard = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--cui-bg-subtle);
    border: none;
    ${theme.mq.untilMega} {
      flex-direction: column-reverse;
    }
  `,
);

export const PosGreetingsContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      margin-top: ${theme.spacings.kilo};
      text-align: center;
    }
    ${theme.mq.mega} {
      max-width: 50%;
    }
  `,
);

export const NavHeader = styled('header')(
  ({
    theme,
    isNavSearchVisible,
    isSearch,
    isPos,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
    isSearch: boolean;
    isPos: boolean;
  }) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.zIndex.navigation};
    background-color: var(--cui-bg-normal);
    border-bottom: 1px solid var(--cui-bg-subtle-pressed);
    border-bottom: ${!isPos && isNavSearchVisible
      ? '1px solid var(--cui-bg-subtle-pressed)'
      : 'var(--cui-bg-normal)'};
    margin-bottom: ${isSearch ? theme.spacings.tera : 0};
    transition: all 0.3s ease-in-out;
    ${isPos ? `padding: ${theme.spacings.byte} 0;` : ''}

    ${theme.mq.untilMega} {
      margin-bottom: ${theme.spacings.giga};
      ${isPos ? `padding: ${theme.spacings.kilo} 0;` : ''}
    }
  `,
);

export const HeaderLink = styled('a')(
  () => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--cui-fg-normal);
    &:hover,
    &:focus {
      color: var(--cui-fg-normal-hovered);
    }
    &:active {
      color: var(--cui-fg-normal-pressed);
    }
  `,
);

export const HeaderLogo = styled(icons.SumUpLogo)(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.mega};
    margin-right: ${theme.spacings.kilo};
    height: calc(${theme.spacings.tera} + ${theme.spacings.bit});
    ${theme.mq.untilMega} {
      height: ${theme.spacings.tera};
    }
  `,
);

export const POSHeaderLogo = styled(SumupLogo)(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.mega};
    margin-right: ${theme.spacings.kilo};
    height: calc(${theme.spacings.tera} + ${theme.spacings.bit});
    ${theme.mq.untilMega} {
      height: ${theme.spacings.tera};
    }
  `,
);

export const LinkToWebsite = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: var(--cui-fg-normal);

    &:hover,
    &:focus {
      color: var(--cui-fg-normal-hovered);
    }

    &:active {
      color: var(--cui-fg-normal-pressed);
    }
  `,
);

export const Navigation = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${theme.zIndex.tooltip};
    background-color: var(--cui-bg-normal);
  `,
);

export const SearchContainer = styled('div')(
  ({ theme, centered }: StyledComponentsParams & { centered: boolean }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${centered ? 'center' : 'flex-start'};
    position: relative;
    z-index: ${theme.zIndex.popover};
  `,
);

export const TopNavSearchContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;

    label {
      width: 100%;
      svg {
        height: ${theme.spacings.peta};
      }
    }
  `,
);

export const SearchSuggestionsContainer = styled('div')(
  ({
    theme,
    topNav,
    fullWidth = false,
  }: StyledComponentsParams & { fullWidth?: boolean; topNav?: boolean }) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    position: absolute;
    z-index: ${theme.zIndex.popover};
    top: ${topNav ? '67px' : '54px'};
    height: 0;

    ${theme.mq.untilMega} {
      width: 100%;
    }

    ${theme.mq.giga} {
      width: ${fullWidth ? '100%' : '50%'};
    }
  `,
);

export const SearchWrapper = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    ${theme.mq.giga} {
      width: 50%;
    }

    & > div {
      margin-bottom: ${theme.spacings.kilo};
    }
  `,
);

const NAV_SEARCH_CONTAINER_STYLES = {
  isNavSearchVisible: ({
    theme,
    isNavSearchVisible,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
  }) =>
    isNavSearchVisible
      ? css`
          margin-top: 0;
          ${theme.mq.giga} {
            margin-top: 0;
          }
        `
      : css`
          margin-top: calc(-${theme.spacings.exa} * 2);
          ${theme.mq.giga} {
            margin-top: calc(-${theme.spacings.exa} * 2);
          }
        `,
  isNavSearchExpanded: ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & {
    isNavSearchExpanded: boolean;
  }) =>
    isNavSearchExpanded
      ? css`
          z-index: ${theme.zIndex.header};
          width: 90%;
          position: absolute;
          left: 50%;
          overflow: visible;
          ${theme.mq.giga} {
            width: 50%;
          }
          ${theme.mq.untilGiga} {
            top: ${theme.spacings.byte};
          }
        `
      : css`
          z-index: ${theme.zIndex.absolute};
          width: 48px;
          position: relative;
          left: calc(${theme.spacings.giga} - ${theme.spacings.bit});
          ${theme.mq.giga} {
            width: calc(${theme.spacings.peta} * 3);
          }
        `,
};

export const NavSearchContainer = styled('div')(
  ({
    theme,
    isNavSearchVisible,
    isNavSearchExpanded,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
    isNavSearchExpanded: boolean;
  }) => css`
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-in-out;
    transition-property: margin-top, width;

    ${NAV_SEARCH_CONTAINER_STYLES.isNavSearchExpanded({
      theme,
      isNavSearchExpanded,
    })};

    ${NAV_SEARCH_CONTAINER_STYLES.isNavSearchVisible({
      theme,
      isNavSearchVisible,
    })};

    ${theme.mq.giga} {
      position: absolute;
      top: 10px;
      left: 50%;
      transition: all 0.3s ease-in-out;
    }

    & > div:first-child,
    & > div:first-child > div {
      width: 100%;
    }
  `,
);

export const NavSearchBar = styled(SearchInput, {
  shouldForwardProp: (prop) => prop !== 'isNavSearchExpanded',
})(
  css`
    & input {
      box-shadow: none;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 0;
    }
  `,
  ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & { isNavSearchExpanded: boolean }) =>
    isNavSearchExpanded &&
    css`
      & input {
        box-shadow: 0 0 0 1px var(--cui-bg-highlight-pressed);
        padding-right: ${theme.spacings.mega};
      }
    `,
);

export const NavigationWrapper = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    ${theme.mq.megaToGiga} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  `,
);

export const NavSearchOverlay = styled('div')(
  ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & { isNavSearchExpanded: boolean }) => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 64px;
    z-index: ${isNavSearchExpanded
      ? `${theme.zIndex.tooltip}`
      : `${theme.zIndex.default}`};
    background-color: var(--cui-bg-normal);
    visibility: ${isNavSearchExpanded ? 'visible' : 'hidden'};
    opacity: ${isNavSearchExpanded ? '1' : '0'};
    transition: all 0.3s ease-in-out;
  `,
);

export const HeaderCountryPickerContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    position: absolute;
    top: calc(${theme.spacings.zetta} + ${theme.spacings.mega});
    left: var(--cui-spacings-tera);
    width: ${COUNTRY_PICKER_WIDTH}px;
    height: ${COUNTRY_PICKER_HEIGHT}px;
    overflow-y: scroll;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--cui-bg-normal);
    border-radius: ${theme.borderRadius.byte};
    z-index: 999;
    .selected {
      color: var(--cui-bg-accent-strong);
      background-color: var(--cui-bg-subtle);
    }
  `,
);

export const HeaderCountryPickerContainerMobile = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0;
`;

export const HeaderCountryPickerOption = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    text-decoration: none;
    border-radius: ${theme.borderRadius.bit};
    margin: ${theme.spacings.bit} 0;
    color: var(--cui-fg-normal);

    &:hover,
    &:focus {
      background-color: var(--cui-bg-normal-hovered);
    }
    &:active {
      background-color: var(--cui-bg-normal-pressed);
    }

    svg {
      width: ${theme.spacings.tera};
      height: ${theme.spacings.giga};
      margin-right: ${theme.spacings.kilo};
    }

    ${theme.mq.giga} {
      width: ${COUNTRY_PICKER_BUTTON_WIDTH}%;
      margin: ${theme.spacings.bit} ${theme.spacings.mega};
    }
  `,
);

export const HeaderCountryPickerOptionContent = styled('span')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadius.bit};
    padding: ${theme.spacings.kilo};
  `,
);

export const CountryPickerTabs = styled(TabList)(
  ({ theme }: StyledComponentsParams) => css`
    position: sticky;
    margin-top: ${theme.spacings.exa};
    top: 0;
    width: 100%;
    border-top-left-radius: ${theme.borderRadius.byte};
    border-top-right-radius: ${theme.borderRadius.byte};
    z-index: ${theme.zIndex.default};

    ${theme.mq.giga} {
      height: ${theme.spacings.exa};
      width: ${COUNTRY_PICKER_WIDTH}px;
    }
  `,
);

export const TabButton = Tab;

export const TabsContainer = styled.div(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    width: 100%;
    top: -${theme.spacings.exa};
    ${theme.mq.giga} {
      top: 0;
    }
  `,
);

export const TabContent = styled(TabPanel)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--cui-bg-normal);
    margin-bottom: ${theme.spacings.mega};
    margin-top: ${theme.spacings.mega};
  `,
);
