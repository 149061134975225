import React, { useCallback } from 'react';
import { TrackingRoot, TrackingView } from '@sumup/collector';

import { AnalyticsEvent, Children } from 'types/common';
import { AnalyticsEvents } from 'services/analytics';

const Collector = ({
  children,
  page,
}: {
  children: Children;
  page: string;
}): JSX.Element => {
  const dispatch = useCallback(
    (event: AnalyticsEvent) => AnalyticsEvents.sendEvent(event),
    [],
  );

  return (
    <TrackingRoot name="Support Centre" onDispatch={dispatch}>
      <TrackingView name={page}>{children}</TrackingView>
    </TrackingRoot>
  );
};

export default Collector;
